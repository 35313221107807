import React from 'react';
import Spinner from './Spinner';
import { observer } from 'mobx-react-lite';

const Redirect = observer(props => {
    return (
        <div className="redirect-loading-container d-flex justify-content-center" style={props.style !== undefined ? props.style : {}}>
            <Spinner/>
        </div>
    )
})

export default Redirect