import { observer } from 'mobx-react-lite'
import SplashUnimateContent from './SplashUnimateContent'
import './assets/splash-content.scss'
import SplashUnimateLemonContent from './UnimateLemonContent'
import StoreSplash from '@Stores/StoreSplash'
import SplashFeelGreatPackContent from './FeelGreatPackContent'
import SplashNZFeelGreatPackContent from './NZFeelGreatPackContent'

const SplashContent = observer(props => {

    return <div className="splash--content">
        {StoreSplash.Template() === 'v1' && <SplashUnimateContent />}
        {StoreSplash.Template() === 'v2' && <SplashUnimateLemonContent />}
        {StoreSplash.Template() === 'v3' && <SplashFeelGreatPackContent />}
        {StoreSplash.Template() === 'v4' && <SplashNZFeelGreatPackContent />}
    </div>
})

export default SplashContent