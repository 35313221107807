import { observer } from 'mobx-react'
import './assets/splash-content.scss'
import { FeelGreatPackContents, SplashBlockType3 } from './SplashItems'
import { Button } from 'antd'
import { language } from '@Language'

const SplashFeelGreatPackContent = observer(props => {
    return (
        <>
            {FeelGreatPackContents.productDescription2.map((v, k) => (
                <SplashBlockType3
                    key={`block-type3-${k}`}
                    cover={v.cover}
                    content={v.content}
                    product={v.type}
                    extraElement={
                        FeelGreatPackContents.productDescription2.length - 1 === k ? (
                            <div className="d-flex justify-content-center buy-now">
                                <Button type="default" onClick={() => window.scrollTo(0, 0)}>
                                    {language.listen('splash_buy_now')}
                                </Button>
                            </div>
                        ) : null
                    }
                />
            ))}
        </>
    )
})

export default SplashFeelGreatPackContent
