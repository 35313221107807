import { inArray } from '@Components/config'
import { dictionary, plainDictionary } from '@Components/GlobalHelpers'
import Card, { CardFooter } from '@Components/Layouts/Card/Card'
import CardBody from '@Components/Layouts/Card/CardBody'
import { isNothing, isString } from '@Components/utils/Utils'
import { language } from '@Language'
import StoreCountry from '@Stores/StoreCountry'
import StoreSplash from '@Stores/StoreSplash'
import { Popover, Tag } from 'antd'
import { reaction, toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import Lightbox from 'react-image-lightbox'
import classnames from 'classnames'
import Button from 'antd/es/button'
import { storeElement } from '@Stores/StoreElement'
import CardHeader from '@Components/Layouts/Card/CardHeader'
import Mobi from '@Components/mobiscroll/Mobi'
import { get } from 'lodash'
import Skeleton from 'antd/es/skeleton'
import HTMLReactParser from 'html-react-parser'
import mobiscroll from '@mobiscroll/react'
import { Country } from '@Stores/StoreConfigCountry'

const PopoverContainer = ({ tooltip }) => {
    return (
        <div className={`prod-tootlip-container d-flex flex-column position-relative p-3`} style={tooltip.dialog.style}>
            <div>{HTMLReactParser(tooltip.content[language.isEnglish() ? 'english' : 'native'])}</div>
        </div>
    )
}

const ImgWithTooltip = ({ src, alt, tooltip }) => {
    const [isVisible, setIsVisible] = useState(false)
    return (
        <Popover
            overlayStyle={{ borderColor: tooltip.dialog.style.backgroundColor }}
            overlayClassName="prod-tootlip-overlay"
            placement="bottomLeft"
            autoAdjustOverflow={true}
            mouseEnterDelay={0.2}
            content={() => <PopoverContainer tooltip={tooltip} />}
            visible={isVisible}
            trigger="hover"
            onVisibleChange={visible => setIsVisible(visible)}>
            <img className="prod-tootlip-img" src={src} alt={alt} onClick={() => setIsVisible(true)} />
        </Popover>
    )
}

const ProductItem = observer(({ item, dimension }) => {
    const [showDrawer, setShowDrawer] = useState(false)
    const [noRemove, setNoRemove] = useState(true)

    const RemoveItem = () => {
        StoreSplash.RemoveItemInCart(item.item_code)

        if (StoreSplash.GetCart().length === 1) {
            window.scrollTo(0, 0)
        }
    }
    // console.log('item===>',toJS(item) )
    return (
        <Card
            className={classnames('', {
                'drawer-delete': showDrawer
            })}>
            {storeElement.isMobile(StoreSplash.IsPaymentPage()) === false && (get(item, 'system_tags', []).length > 0 || get(item, 'tags', []).length > 0) && (
                <CardHeader>
                    <div className="splash--product-tag">{generateTags(item)}</div>
                </CardHeader>
            )}
            <CardBody
                className={classnames('splash--product-item', {
                    hide: showDrawer,
                    'has-tag': get(item, 'system_tags', []).length > 0 || get(item, 'tags', []).length > 0
                })}>
                <div className={classnames('splash--product-image')}>{item.tooltip ? <ImgWithTooltip src={item.image_url} alt={`Product-${item.id}`} tooltip={toJS(item.tooltip)} /> : <img src={item.image_url} alt={`Product-${item.id}`} />}</div>
                <div
                    className={classnames('splash--product-content', {
                        'no-item-code': !StoreSplash.ShowItemCode()
                    })}>
                    <h4>{item.item_name[language.isEnglish() ? 'english' : 'native']}</h4>
                    {storeElement.isMobile(StoreSplash.IsPaymentPage()) === false && <MoreInfo item={item} />}
                    {StoreSplash.ShowItemCode() && <p className="text-muted">CODE: {item.item_code}</p>}
                    <p className="item-price">
                        {item.price} {StoreSplash.Currency()}
                    </p>
                    {storeElement.isMobile(StoreSplash.IsPaymentPage()) === true && <MoreInfo item={item} />}
                    {storeElement.isMobile(StoreSplash.IsPaymentPage()) === true && (get(item, 'system_tags', []).length > 0 || get(item, 'tags', []).length > 0) && <div className="splash--product-tag">{generateTags(item)}</div>}
                    {StoreSplash.IsFirstPage() && <ProductActions item={item} isSplashKit={item.isSplashKit} setShowDrawer={setShowDrawer} setNoRemove={setNoRemove} />}
                </div>
            </CardBody>
            {StoreSplash.IsPaymentPage() && (
                <CardFooter>
                    <div className="splash--product-item-footer-qty">
                        <div>x {item.qty}</div>
                    </div>
                    <div>
                        <div>
                            {StoreSplash.GetItemPrice(item.item_code)} {StoreSplash.Currency()}
                        </div>
                    </div>
                </CardFooter>
            )}
            {showDrawer && (
                <CardBody>
                    <div className="drawer-actions">
                        {dictionary('u_all_cart_modal_remove_description')}
                        <div className="buttons">
                            <Button
                                type="primary"
                                onClick={() => {
                                    setShowDrawer(false)
                                    RemoveItem()
                                }}>
                                {dictionary('yes')}
                            </Button>
                            <Button
                                type="ghost"
                                onClick={() => {
                                    setShowDrawer(false)
                                    setNoRemove(true)
                                }}>
                                {dictionary('no')}
                            </Button>
                        </div>
                    </div>
                </CardBody>
            )}
        </Card>
    )
})

const MoreInfo = ({ item }) => {
    const [toggle, setToggle] = useState(false)
    const [enabled, setEnabled] = useState(false)
    const [images, setImages] = useState([])
    const [photoIndex, setPhotoIndex] = useState(0)

    useEffect(() => {
        let info_list = 'english'

        if (language.footer == 'native') {
            info_list = 'native'
        }
        const rawInfoList = toJS(item.item_info_list[info_list])

        if (rawInfoList) {
            if (rawInfoList.length > 0) {
                setImages(rawInfoList)
                setEnabled(true)
            } else {
                setImages([])
                setEnabled(false)
            }
        } else {
            setImages([])
            setEnabled(false)
        }
    }, [])

    return (
        <>
            {enabled ? (
                <>
                    <span>
                        <a title={dictionary('more_info')} className="more-info text-primary" onClick={() => setToggle(true)}>
                            {dictionary('more_info')}
                        </a>
                    </span>

                    {toggle && images.length > 0 && (
                        <Lightbox
                            mainSrc={images[photoIndex]}
                            nextSrc={images.length > 1 ? images[(photoIndex + 1) % images.length] : null}
                            prevSrc={images.length > 1 ? images[(photoIndex + images.length - 1) % images.length] : null}
                            onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                            onCloseRequest={() => setToggle(false)}
                        />
                    )}
                </>
            ) : null}
        </>
    )
}

const generateTags = item => {
    const tagBody = feature => {
        let color, value, key

        if (isString(feature)) {
            key = feature
        }

        const c = StoreCountry.Country2()
        const globalPrefix = `u_all_tag_`
        const nativePrefix = `u_${c}_tag_`

        const keyTemplate = {
            outOfStock: {
                color: {
                    native: `${nativePrefix}color_out_of_stock`,
                    global: `${globalPrefix}color_out_of_stock`
                },
                value: {
                    native: `${nativePrefix}value_out_of_stock`,
                    global: `${globalPrefix}value_out_of_stock`
                }
            },
            backOrder: {
                color: {
                    native: `${nativePrefix}color_back_order`,
                    global: `${globalPrefix}color_back_order`
                },
                value: {
                    native: `${nativePrefix}value_back_order`,
                    global: `${globalPrefix}value_back_order`
                }
            }
        }

        const keyOutOfStockColor = plainDictionary(keyTemplate.outOfStock.color.native) ? keyTemplate.outOfStock.color.native : keyTemplate.outOfStock.color.global
        const keyOutOfStockValue = plainDictionary(keyTemplate.outOfStock.value.native) ? keyTemplate.outOfStock.value.native : keyTemplate.outOfStock.value.global
        const keyBackOrderColor = plainDictionary(keyTemplate.backOrder.color.native) ? keyTemplate.backOrder.color.native : keyTemplate.backOrder.color.global
        const keyBackOrderValue = plainDictionary(keyTemplate.backOrder.value.native) ? keyTemplate.backOrder.value.native : keyTemplate.backOrder.value.global

        switch (key) {
            case 'outofstock':
                color = dictionary(keyOutOfStockColor)
                value = dictionary(keyOutOfStockValue)
                break
            case 'backorder':
                color = dictionary(keyBackOrderColor)
                value = dictionary(keyBackOrderValue)
                break
            default:
                const keyt = {
                    native: feature.native,
                    global: feature.english
                }

                color = feature.color

                if (isNothing(color)) {
                    color = '#B4B4B4'
                }

                value = language.isEnglish() ? feature.english : feature.native ? feature.native : feature.english

                break
        }

        return (
            <Tag color={color} style={{ marginBottom: '5px' }} key={key}>
                {value}
            </Tag>
        )
    }

    const tags = []

    item.system_tags.map((v, k) => {
        if (v) {
            const featureEnglish = v.text.english
            const featureNative = v.text.native
            if (featureEnglish || featureNative) {
                tags.push(tagBody({ english: featureEnglish, native: featureNative, color: v.style.background_color }))
            }
        }
    })

    item.tags.map((v, k) => {
        if (v) {
            const featureEnglish = v.text.english
            const featureNative = v.text.native
            if (featureEnglish || featureNative) {
                tags.push(tagBody({ english: featureEnglish, native: featureNative, color: v.style.background_color }))
            }
        }
    })

    return tags
}

const ProductActions = observer(({ item, isSplashKit, setShowDrawer, noRemove }) => {
    let ItemInCart = StoreSplash.GetItemInCart(item.item_code)
    let disableInput = false
    if (item.status == 'out_of_stock' || item.qty_sellable == 0) {
        disableInput = true
    }

    const [qtyCurrent, setQtyCurrent] = useState(0)

    const plusMinusCart = (type, qty, event, id, code) => {
        if (type === 'input') {
            qty = parseInt(qty)

            if (isNaN(qty)) {
                qty = 0
            }
            if (qty < 1) {
                qty = 0
            } else if (qty >= 999) {
                qty = 999
            }

            let a = []

            if (qty === 0) {
                if (StoreSplash.HasItemInCart(code) && isSplashKit === false) {
                    setQtyCurrent(qty)
                    setShowDrawer(true)
                } else {
                    if (StoreSplash.HasMultipleSplashKit()) {
                        qty = 0
                    } else {
                        qty = 1
                    }
                }
            }

            ItemInCart.qty = qty
            StoreSplash.SetItemInCart(id, qty)
        } else {
            qty = parseInt(qty)
            if (type === 'minus') {
                if (qty < 1) {
                    qty = 0
                    if (id == '895' && Country.isPhilippines()) {
                        qty = 1
                    }
                } else {
                    qty = parseInt(qty) - parseInt(1)

                    if (qty === 0 && id == '895' && Country.isPhilippines()) {
                        qty = 1
                    }
                }
            } else {
                if (qty > ItemInCart.qty_sellable) {
                    qty = ItemInCart.qty_sellable
                } else {
                    qty = parseInt(qty) + parseInt(1)
                }
            }

            let confirmRemove = false
            if (qty === 0) {
                if (!isSplashKit) {
                    if (StoreSplash.HasItemInCart(code)) {
                        confirmRemove = true
                        setShowDrawer(true)
                    } else {
                        qty = 1
                    }
                }
            }

            if (confirmRemove === false) {
                ItemInCart.qty = qty
                StoreSplash.SetItemInCart(id, qty)
            }
        }
    }

    return (
        <>
            {ItemInCart && (
                <div className="product-actions">
                    <div className="block-qty">
                        <div className={isSplashKit || ItemInCart.qty === '0' || !ItemInCart || disableInput ? 'qtyInput mb-1 qtyInputDisable' : 'qtyInput mb-1'}>
                            <span>
                                <button
                                    className={classnames('qtyplusminusbtn borderRight')}
                                    onClick={event => plusMinusCart('minus', ItemInCart.qty, event, ItemInCart.id, ItemInCart.item_code)}
                                    disabled={parseInt(ItemInCart.qty) <= (StoreSplash.HasMultipleSplashKit() ? 0 : 1) && ItemInCart.isSplashKit ? true : false}>
                                    <IconMinus />
                                </button>
                            </span>
                            <input
                                type="text"
                                min={ItemInCart.qty === '0' ? '0' : '1'}
                                max="99"
                                className="form-control-number-new"
                                value={ItemInCart.qty === '0' ? '0' : ItemInCart.qty}
                                style={{ maxWidth: '60px', textAlign: 'center' }}
                                onFocus={e => {
                                    e.target.select()
                                }}
                                onChange={event => plusMinusCart('input', event.target.value, event, ItemInCart.id, ItemInCart.item_code)}
                            />
                            <span>
                                <button
                                    className={classnames('qtyplusminusbtn borderLeft', {
                                        'text-muted': ItemInCart.qty >= parseInt(ItemInCart.qty_sellable)
                                    })}
                                    onClick={event => plusMinusCart('plus', ItemInCart.qty, event, ItemInCart.id, ItemInCart.item_code)}
                                    disabled={ItemInCart.qty >= parseInt(ItemInCart.qty_sellable) ? true : false}>
                                    <IconPlus />
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            )}
            {!ItemInCart && <span></span>}
        </>
    )
})

const IconMinus = () => {
    return (
        <svg width="20" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5714 0.857142C12.5714 0.383928 12.1875 0 11.7143 0H0.857143C0.383929 0 0 0.383928 0 0.857142V2.57143C0 3.04464 0.383929 3.42857 0.857143 3.42857H11.7143C12.1875 3.42857 12.5714 3.04464 12.5714 2.57143V0.857142Z"
                fill="black"
            />
        </svg>
    )
}

const IconPlus = () => {
    return (
        <svg width="14" height="24" viewBox="0 0 15 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5714 5.85704C12.5714 5.38382 12.1875 4.9999 11.7143 4.9999H8V1.28561C8 0.812395 7.61607 0.428467 7.14286 0.428467H5.42857C4.95536 0.428467 4.57143 0.812395 4.57143 1.28561V4.9999H0.857143C0.383929 4.9999 0 5.38382 0 5.85704V7.57132C0 8.04454 0.383929 8.42847 0.857143 8.42847H4.57143V12.1428C4.57143 12.616 4.95536 12.9999 5.42857 12.9999H7.14286C7.61607 12.9999 8 12.616 8 12.1428V8.42847H11.7143C12.1875 8.42847 12.5714 8.04454 12.5714 7.57132V5.85704Z"
                fill="black"
            />
        </svg>
    )
}

const SelectingProductLayout = ({ selectOptions, index }) => {
    let noItem = true
    let removeIcon = noItem ? 'show-icon' : ''
    let display = storeElement.isMobile() ? 'bottom' : 'bubble'
    return (
        <Mobi.Select
            filter={true}
            height={35}
            maxWidth={450}
            data={StoreSplash.SelectProducts()}
            buttons={[]}
            filterPlaceholderText={dictionary('type_to_search')}
            index={index}
            hideSelection
            onSet={(selectedValue, inst) => {
                var selected = inst.getVal()
                if (selected) {
                    onSelected(selected, index)
                    StoreSplash.setAdditionalProducts(StoreSplash.additionalProducts - 1)
                    // setTimeout(() => InitItems(), 500)
                }
            }}
        />
    )
}

const onSelected = (value, index) => {
    let product = StoreSplash.getProduct(value)
    if (product) {
        let price = product.price

        if (price == 0) {
            return false
        }

        // let stock = product.stock
        // stock = stock === '' || stock === null || stock === undefined || parseInt(stock) === NaN ? 0 : parseInt(stock)

        let disableInput = false

        if (product.status == 'out_of_stock' || product.qty_sellable == 0) {
            disableInput = true
        }

        let cartItem = StoreSplash.HasItemInCart(product.item_code)
        if (cartItem) {
            if (disableInput) {
                cartItem.qty = 0
            } else {
                cartItem = product
                cartItem.isSplashKit = false
            }
        } else {
            StoreSplash.SetItemInCart(product.item_code, 1, { isSplashKit: false })
        }
    }
}

export const ProductLayoutMockup = props => {
    const Action = props => {
        return (
            <div className="splash--product-content">
                <div className="product-actions">
                    {props.selectProduct}
                    <div className="block-qty">
                        <div className="qtyInput mb-1 qtyInputDisable">
                            <span>
                                <button
                                    className={'qtyplusminusbtn borderRight'}
                                    onClick={() => {
                                        StoreSplash.setAdditionalProducts(StoreSplash.additionalProducts - 1)
                                    }}>
                                    <IconMinus />
                                </button>
                            </span>
                            <input type="text" min="0" max="99" className="form-control-number-new" value="0" style={{ maxWidth: '60px', textAlign: 'center' }} disabled={true} />
                            <span>
                                <button className={'qtyplusminusbtn borderLeft'} disabled={true}>
                                    <IconPlus />
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const SelectProduct = props => {
        const [selectOptions, setSelectOptions] = useState([])

        return (
            <div className="product-info">
                <div className="wrap">
                    <SelectingProductLayout selectOptions={selectOptions} />
                </div>
            </div>
        )
    }

    const Image = props => {
        return (
            <div className="splash--product-image">
                <img src={require('../../../assets/productfornew.svg').default} alt="product" />
            </div>
        )
    }

    return (
        <div className="splash--additional-products-mockup">
            {storeElement.isMobile() && (
                <Card>
                    <CardHeader>
                        <SelectProduct />
                    </CardHeader>
                    <CardBody className="splash--product-item">
                        <Image />
                        <Action />
                    </CardBody>
                </Card>
            )}
            {!storeElement.isMobile() && (
                <Card>
                    <CardBody className="splash--product-item">
                        <Image />
                        <Action selectProduct={<SelectProduct />} />
                    </CardBody>
                </Card>
            )}
        </div>
    )
}

const IconPlusMobile = () => {
    return (
        <svg width="22" height="25" viewBox="0 0 20 3" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5714 5.85704C12.5714 5.38382 12.1875 4.9999 11.7143 4.9999H8V1.28561C8 0.812395 7.61607 0.428467 7.14286 0.428467H5.42857C4.95536 0.428467 4.57143 0.812395 4.57143 1.28561V4.9999H0.857143C0.383929 4.9999 0 5.38382 0 5.85704V7.57132C0 8.04454 0.383929 8.42847 0.857143 8.42847H4.57143V12.1428C4.57143 12.616 4.95536 12.9999 5.42857 12.9999H7.14286C7.61607 12.9999 8 12.616 8 12.1428V8.42847H11.7143C12.1875 8.42847 12.5714 8.04454 12.5714 7.57132V5.85704Z"
                fill="#333"
            />
        </svg>
    )
}

const AddItem = () => {
    // StoreSplash.setAdditionalProducts(StoreSplash.additionalProducts += 1)
}

const AdditionalProductsWrapper = observer(props => {
    const [totalItems, setTotalItems] = useState({
        items: 0,
        itemsPerRow: 0
    })

    const initLayout = () => {
        const itemWidth = 320
        const windowWidth = window.innerWidth - 40
        const itemsPerRow = Math.floor(windowWidth / itemWidth)

        setTotalItems({
            items: StoreSplash.GetCart().length - 1 + StoreSplash.additionalProducts > itemsPerRow ? itemsPerRow : StoreSplash.GetCart().length - 1 + StoreSplash.additionalProducts,
            itemsPerRow: itemsPerRow
        })
    }

    useEffect(() => {
        initLayout()

        const disposeInitLayout = reaction(
            () => StoreSplash.additionalProducts,
            () => {
                initLayout()
            }
        )

        return () => {
            disposeInitLayout()
        }
    }, [])
    return (
        <>
            <div className="splash--additional-products-wrapper">
                <h3>{language.listen('splash_additional_purchases')}</h3>
                <div className="splash--additional-section" style={{ gridTemplateColumns: `repeat(3, 300px)` }}>
                    {StoreSplash.GetCart?.().map((item, k) => {
                        if (item.isSplashKit !== true) {
                            return <ProductItem item={item} key={item.item_code} isStarterKit={true} />
                        } else {
                            return false
                        }
                    })}
                    {StoreSplash.IsFirstPage() && (
                        <>
                            {[...new Array(StoreSplash.additionalProducts)].map((v, k) => {
                                return <ProductLayoutMockup key={`${new Date().getTime()}${k}`} index={`${new Date().getTime()}${k}`} />
                            })}
                        </>
                    )}
                </div>
                {StoreSplash.IsFirstPage() && (
                    <div
                        className="block_addmore "
                        onClick={() => {
                            if (props.selectBoxRef && props.selectBoxRef.current) {
                                props.selectBoxRef.current.instance.show()
                            }
                        }}>
                        <IconPlusMobile />
                        &nbsp;&nbsp; <span>{dictionary('add_more_products')}</span>
                    </div>
                )}
            </div>
        </>
    )
})

const ProductItemsWrapper = observer(props => {
    const selectBoxRef = useRef()

    const onSelected = selected => {
        StoreSplash.SetItemInCart(selected, 1)
    }

    return (
        <>
            <div
                className={classnames('block-shopping-cart', {
                    'block-grid-shopping-cart-1': StoreSplash.splashKitItems && StoreSplash.splashKitItems.length === 1,
                    'block-grid-shopping-cart-2': StoreSplash.splashKitItems && StoreSplash.splashKitItems.length === 2,
                    'block-grid-shopping-cart-3': StoreSplash.splashKitItems && StoreSplash.splashKitItems.length >= 3
                })}>
                {StoreSplash.GetCart?.().length > 0 &&
                    StoreSplash.GetCart?.().map((item, k) => {
                        if ((StoreSplash.IsPaymentPage() && item.qty > 0) || (StoreSplash.IsFirstPage() && item.isSplashKit)) {
                            return <ProductItem item={item} key={item.item_code} isStarterKit={true} {...props} />
                        } else {
                            return false
                        }
                    })}
                {StoreSplash.GetCart?.().length === 0 && (
                    <>
                        {storeElement.isMobile() && <Skeleton.Avatar active style={{ width: window.innerWidth < 414 ? '100%' : 374, height: 224, borderRadius: 7 }} />}
                        {storeElement.isMobile() === false && (
                            <>
                                {StoreSplash.IsFirstPage() && <Skeleton.Avatar active style={{ width: 300, height: 410, borderRadius: 7 }} />}
                                {StoreSplash.IsPaymentPage() && <Skeleton.Avatar active style={{ width: 360, height: 240, borderRadius: 7 }} />}
                            </>
                        )}
                    </>
                )}
            </div>
            {StoreSplash.SelectProducts().length > 0 && (StoreSplash.HasMultipleSplashKit() ? StoreSplash.cart.length === 3 : StoreSplash.cart.length === 1) && StoreSplash.IsFirstPage() && (
                <div
                    className="block_addmore "
                    onClick={() => {
                        if (selectBoxRef.current) {
                            selectBoxRef.current.instance.show()
                        }
                    }}>
                    <IconPlusMobile />
                    &nbsp;&nbsp; <span>{dictionary('add_more_products')}</span>
                </div>
            )}
            {(StoreSplash.HasMultipleSplashKit() ? StoreSplash.cart.length > 3 : StoreSplash.cart.length > 1) && StoreSplash.IsFirstPage() && <AdditionalProductsWrapper selectBoxRef={selectBoxRef} />}
            {StoreSplash.SelectProducts().length > 0 && (
                <div className="d-none">
                    <Mobi.Select
                        display="center"
                        touchUi={false}
                        filter={true}
                        data={StoreSplash.SelectProducts()}
                        filterPlaceholderText={dictionary('type_to_search')}
                        buttons={[]}
                        ref={selectBoxRef}
                        placeholder={language.listen('u_all_genealogy_modal_search')}
                        hideSelection
                        onSet={(event, inst) => {
                            var selected = inst.getVal()
                            if (selected) {
                                onSelected(selected)
                            }
                        }}
                        onShow={() => {
                            selectBoxRef.current.instance.refresh()
                        }}
                    />
                </div>
            )}
        </>
    )
})

export default ProductItemsWrapper
