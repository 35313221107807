import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { staticFooter } from '@Stores/MainStore'
import { Country } from '@Stores/StoreConfigCountry'
import { LanguageList } from '@Configs/Language'
import StoreHeader from '@Stores/StoreHeader'
import { dictionary } from '@GlobalHelpers'
import { isNothing } from '@Utils/Utils'
import { appConfig } from '@Config'
import { language } from '@Language'
import { LoadingOutlined } from '@ant-design/icons'
import Shape from '@Assets/Shape.svg'
import get from 'lodash/get'
import styled from 'styled-components'

const Header = observer(props => {
    const isEnroll = () => /enroll/.test(window.location.href)
    const isAutoship = () => /autoship/.test(window.location.href)
    const isRenewal = () => /renewal/.test(window.location.href)
    const isShowMenu = () => isEnroll() || isAutoship() || isRenewal()

    const onClickMenu = () => {
        let destination = ''
        if (isAutoship()) {
            if (Country.isAustralia()) {
                destination = `//express.unicity.com/australia/#/`
            } else {
                destination = `//ushop.unicity.com/${Country.getCountry()}/menu`
            }
        } else {
            if (Country.getCountry() === 'cambodia') {
                destination = `/${Country.getCountry()}/enroll/detail`
            } else {
                destination = `//ushop.unicity.com/${Country.getCountry()}/menu`
            }
        }
        window.location.href = destination
    }

    return (
        <div style={{ height: 75 }}>
            <header style={{ height: 83, width: '100%', backgroundColor: 'white' }} hidden />
            <header className="header-customlink wrap-layout navbar navbar-sticky navbar-stuck" id="headerBar">
                <div className="site-branding" style={{ left: 'auto' }}>
                    <div className="inner text-center">
                        {isShowMenu() && (
                            <div className="inner-menu" onClick={onClickMenu}>
                                <div className="offcanvas-toggle"></div>
                            </div>
                        )}
                        <a className="site-logo text-center">
                            <img src={Shape} alt="USHOP" />
                        </a>
                    </div>
                </div>
                <HeaderTitle />
                <div className="header-flag-lang">
                    <RenderCurrentCountry />
                </div>
            </header>
        </div>
    )
})

const HeaderTitle = observer(props => {
    const history = useHistory()

    useEffect(() => {
        history.listen(r => {
            StoreHeader.setHeaderTitle(r)
        })
    }, [])

    return (
        <div className="extra-page-title">
            {StoreHeader.Title && <h1>{!/^u_/.test(StoreHeader.Title) ? dictionary(StoreHeader.Title) : language.listen(StoreHeader.Title)}</h1>}
        </div>
    )
})

const RenderCurrentCountry = observer(props => {
    return (
        <StyledCountryDisplay country={staticFooter.country}>
            <span className={`${get(staticFooter.country, 'code_shorter.english')} country-block`}>
                {(() => {
                    if (isNothing(staticFooter.country)) {
                        return <LoadingOutlined spin />
                    } else {
                        if (language.isEnglish()) {
                            return staticFooter.country.name.english
                        } else if (LanguageList[appConfig.country].length > 1) {
                            return staticFooter.country.name.native
                        } else {
                            return ''
                        }
                    }
                })()}
            </span>
        </StyledCountryDisplay>
    )
})

const StyledCountryDisplay = styled.span`
    color: white;
    text-transform: uppercase;
    top: ${({ country }) => (isNothing(country) ? '-0.15em' : '0')};
    position: relative;

    .ant-divider {
        opacity: 0.3;
        margin: 0 6px;
    }

    .ant-divider-vertical {
        border-width: 1.3px;
        top: -0.08em;
    }
`

export default Header
