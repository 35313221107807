import { observer } from 'mobx-react'
import './assets/splash-products.scss'
import ProductItemsWrapper from './SplashProducts/ProductItem'

const SplashProducts = observer(props => {
    return (
        <div className="splash--items-wrapper">
            <ProductItemsWrapper dimensions={props.dimensions} />
        </div>
    )
})

export default SplashProducts
