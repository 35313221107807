/* eslint-disable react/prop-types */
import { dictionary } from '@Components/GlobalHelpers'
import Spinner from '@Components/Spinner'
import { Modal } from 'antd'
import React, { useState, useCallback, useRef, useEffect } from 'react'
import { Else, If, Then, When } from 'react-if'
import { ReactComponent as CameraIcon } from './camera.svg'
import 'react-image-crop/dist/ReactCrop.css'
import './image-upload.scss'
import KycUploader from './kycUploader'
import StoreCountry from '@Stores/StoreCountry'
import ImageTools from '@Utils/ImageTool'
import imageResizeFunc from '@Components/enroll/IDVerification/hooks/useResizeImage'
import { Country } from '@Stores/StoreConfigCountry'

const ImageUpload = props => {
    const [loadingPicture, setLoadingPicture] = useState(false)

    const [imgUploadLoading, setImgUploadLoading] = useState(false)

    const [showCropModal, setShowCropModal] = useState(false)

    const [imageBeforeCrop, setImageBeforeCrop] = useState(null)
    const [imageCropped, setImageCropped] = useState(null)
    const [imageCroppedForUpload, setImageCroppedForUpload] = useState(null)

    const [fileName, setFileName] = useState(null)

    const [disabledButton, setDisabledButton] = useState(false)

    const [originalFile, setOriginalFile] = useState(null)

    const imageCropRef = useRef()

    const inputFile = useRef(null)

    const [crop, setCrop] = useState({
        unit: '%',
        width: 100,
        height: 100
    })

    const [imgResizedDimension, setImgResizedDimension] = useState({ width: 0, height: 0 })
    const [imageResized, setImageResized] = useState(null)

    const [watermarkUrl, setWatermarkUrl] = useState(null)

    const onButtonClick = () => {
        inputFile.current.click()
    }

    const onFileChange = async e => {
        const { files } = e.target
        if (files && files.length) {
            setImgUploadLoading(true)

            if (imageCropped) {
                setImageCropped(null)
            }

            if (imageBeforeCrop) {
                setImageBeforeCrop(null)
            }

            const file = e.target.files[0]
            setOriginalFile(file)
            setFileName(file.name)
            try {
                let imgResized = await imageResizeFunc(file)
                setImageResized(imgResized)
            } catch (e) {
                console.log('error', e)
                setImageResized(file)
            }
        }
    }

    useEffect(async () => {
        // if (props.resize && imageResized) {
        if (imageResized) {
            const result = await callWatermarkAsset(imageResized, '', fileName, props.noWatermark, props.resize)
            setImageBeforeCrop(result)
        }
    }, [imageResized])

    // useEffect(async () => {
    //     const defaultWatermarkUrl = props.watermarkUrl
    //     setWatermarkUrl(await getBase64FromUrl(defaultWatermarkUrl))
    // }, [])

    const stepByStepResize = (maxWidth, maxHeight, sourceImage) => {
        const img = new Image()
        img.src = sourceImage

        img.onload = () => {
            const width = maxWidth
            // Now for the efficient loop solution
            var oc = document.createElement('canvas'),
                octx = oc.getContext('2d')

            oc.width = img.width
            oc.height = img.height
            octx.drawImage(img, 0, 0)

            if (img.width > maxWidth) {
                while (oc.width * 0.5 > width) {
                    oc.width *= 0.5
                    oc.height *= 0.5
                    octx.drawImage(oc, 0, 0, oc.width, oc.height)
                }
                oc.width = width
                oc.height = (oc.width * img.height) / img.width
                octx.drawImage(img, 0, 0, oc.width, oc.height)
            }

            return new Promise((resolve, reject) => {
                oc.toBlob(
                    blob => {
                        if (!blob) {
                            reject(new Error('Canvas is empty'))
                            return
                        }
                        blob.name = 'new.jpg'

                        setImageResized(blob)
                        resolve(blob)
                    },
                    'image/jpeg',
                    1
                )
            })
        }
    }

    const getBase64FromUrl = async url => {
        const data = await fetch(url)
        const blob = await data.blob()
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                const base64data = reader.result
                resolve(base64data)
            }
            reader.onerror = e => {
                console.log(e)
                reject(e)
            }
        })
    }

    const cancelCrop = () => {
        setShowCropModal(false)
        setLoadingPicture(false)
        setImgUploadLoading(false)
        setImageBeforeCrop(null)
        setImageCroppedForUpload(null)
    }

    const onClickUpload = async () => {
        setLoadingPicture(true)
        setImgUploadLoading(true)
        setShowCropModal(false)
        setImageCropped(null)

        // callWatermarkAsset(imageCroppedForUpload)

        const reader = new FileReader()
        reader.onload = async e => {
            await callWatermarkAsset(imageCroppedForUpload, '', fileName, props.noWatermark)
        }
        reader.readAsDataURL(originalFile)
    }

    const toBase64 = async imgData =>
        new Promise((resolve, reject) => {
            try {
                const reader = new FileReader()
                reader.readAsDataURL(imgData)
                reader.onload = e => resolve(reader.result)
                reader.onerror = reject
            } catch (err) {
                console.error(err)
                reject(err)
            }
        })

    const callWatermarkAsset = async (imgData, indexID, imgName, isNoWaterMark, isResize) => {
        const SetCallback = img => {
            props.setImageCallback(img)
            setImgUploadLoading(false)
            setLoadingPicture(false)
        }

        const base64img = await toBase64(imgData)
        const resizeWatermark = [100, 75, 50, 25]
        const defaultResizeWaterMark = Country.isMatchedCountry(['TH']) ? 50 : 100
        const options = {
            countryCode: StoreCountry.Country3(),
            GID: props.govid,
            dataUrl: base64img,
            name: props.name,
            // watermarkResizeTo: props.watermarkResizeTo ? resizeWatermark[props.watermarkResizeTo] : resizeWatermark[2],
            watermarkResizeTo: props.watermarkResizeTo || defaultResizeWaterMark,
            isWatermark: !isNoWaterMark,
            watermarkUrl: props.watermarkUrl,
            position: props.position
        }

        try {
            let res
            if (props.name === 'selfie') res = await KycUploader.uploadSelfie(options)
            else if (props.name === 'bookbank' || props.name === 'icFileBBName') res = await KycUploader.uploadBankbook(options)
            else if (props.name === 'passport' || props.name === 'icPassportFile') res = await KycUploader.uploadPassport(options)
            else if (props.name === 'workpermit' || props.name === 'icWorkPermitFile') res = await KycUploader.uploadWorkPermit(options)
            else res = await KycUploader.uploadGID(options)
            SetCallback(res.imageUrl)
            const { confidence, detectedText } = await props.detectFace(imageResized, props.detectType, props.detectFaceRequired, props.detectTextType)
            props.setDetectedImage(confidence, detectedText)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className={`take-a-photo--wrapper ${imageCropped || props.hasImage !== false ? 'has-cropped-image' : ''}`} onClick={onButtonClick}>
            <Modal
                style={{ top: 20, width: 'auto!important' }}
                destroyOnClose={true}
                visible={showCropModal}
                onCancel={cancelCrop}
                className="upload-modal-crop"
                afterClose={() => setImageCropped(null)}
                maskClosable={false}
                closable={false}
                footer={[
                    <button className="btn btn-primary" key="done" onClick={() => onClickUpload()} disabled={disabledButton}>
                        {dictionary('u_all_dashboard_done')}
                    </button>,
                    <button className="btn btn-default" key="cancel" onClick={cancelCrop}>
                        {dictionary('cancel')}
                    </button>
                ]}>
                <img src={imageBeforeCrop} ref={imageCropRef} />
            </Modal>
            <div className={`id-card-box ${imgUploadLoading === true ? 'loading' : ''} uploadphoto`}>
                <If condition={loadingPicture}>
                    <Then>
                        <div className="upload-image">
                            <Spinner />
                        </div>
                    </Then>
                    <Else>
                        <div className="id-card cursor-pointer">
                            <If condition={props.coverPhoto !== undefined && props.hasImage === false}>
                                <Then>
                                    <If condition={imgUploadLoading === true}>
                                        <Then>
                                            <div className="upload-image">
                                                <Spinner />
                                            </div>
                                        </Then>
                                        <Else>{props.coverPhoto}</Else>
                                    </If>
                                </Then>
                                <Else>
                                    <If condition={imgUploadLoading === true}>
                                        <Then>
                                            <div className="d-flex justify-content-center align-items-center w-100 h-100">
                                                <div className="upload-image">
                                                    <Spinner />
                                                </div>
                                            </div>
                                        </Then>
                                        <Else>
                                            <If condition={props.hasImage !== false}>
                                                <Then>
                                                    <div className="media-body-avatar">
                                                        <div className="welcome-avatar-wrapper cursor-pointer">
                                                            <div className="profile-picture">
                                                                <span className="ant-avatar ant-avatar-square ant-avatar-image">
                                                                    <img src={props.hasImage} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Then>
                                                <Else>
                                                    <When condition={imageBeforeCrop !== null}>
                                                        <img src={imageBeforeCrop} />
                                                    </When>
                                                </Else>
                                            </If>
                                        </Else>
                                    </If>
                                </Else>
                            </If>
                        </div>
                        <When condition={props.hasImage === false}>
                            <div className="camera">
                                <CameraIcon />
                            </div>
                        </When>
                    </Else>
                </If>
                <input type="file" accept="image/jpg, image/jpeg, image/png" name={props.name} style={{ display: 'none' }} value={''} ref={inputFile} onChange={onFileChange} />
            </div>
            <When condition={props.hasImage !== false && imgUploadLoading === false}>
                <div className="camera" style={{ marginTop: 30 }}>
                    <CameraIcon />
                </div>
            </When>
        </div>
    )
}

export default ImageUpload
