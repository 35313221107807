import React, { Component } from 'react'
import { observer } from 'mobx-react'


const CardBody = observer(

    class CardBody extends Component {

        /* constructor(props) {
            super(props)
        } */

        render() {

            const props = {...this.props}

            const classProps = `card-body ${props.className ? props.className : ''}`

            const style = this.props.style ? this.props.style : {}

            return (<div className={classProps} style={style}>
                {props.children}
            </div>)
        }
    }

)

export default CardBody