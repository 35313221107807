import React, { useState, useEffect } from 'react';
import { isCountryPhilippines } from "./GlobalHelpers";
import { observer } from 'mobx-react';
import { defaults, trim, isNothing } from './utils/Utils';
import PropTypes from 'prop-types';
import { Country } from '@Stores/StoreConfigCountry';

const Spinner = observer(props => {
    //const defaultSpinnerClassName = isCountryPhilippines() ? 'loadingImagePH' : 'loadingImage'
    const defaultSpinnerClassName = 'loadingImage'
    const defaultClassName = 'container'

    Spinner.propTypes = {
        spinnerClassName: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]),
        className: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]),
        style: PropTypes.object,
    }

    const [className, setClassName] = useState(defaults(props.className, defaultClassName))

    const { spinnerClassName, ...customProps } = props

    useEffect(() => {
        setClassName(trim(`${defaultClassName} ${defaults(props.className, '')}`))
    }, [props])

    return (
        <div {...customProps} className={`${className} justify-content-center d-flex align-items-center`} style={{height: '100%', width: '100%'}}>
            <div className={isNothing(spinnerClassName) ? defaultSpinnerClassName : spinnerClassName}></div>
        </div>
    )
})

export default Spinner