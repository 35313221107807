import Unimate from './assets/img/unimate.png'
import UnimatePack from './assets/img/unimate-pack.jpg'
import UnimateProductDescription from './assets/img/unimate-description.jpg'
import UnimateIngredient from './assets/img/unimate-ingredient.jpg'
import Unimate2 from './assets/img/unimate2.jpg'

import { ReactComponent as UnimateLemon } from './assets/img/lemon/unimate-lemon.svg'
import UnimateLemon2 from './assets/img/lemon/unimate-lemon2.png'
import UnimateLemonProductDescription from './assets/img/lemon/unicity-lemon-description.png'
import NutritionalImage from './assets/img/lemon/nutritional-lemon.png'
import UnimateProductDescriptionDesktop from './assets/img/lemon/unimate-description-desktop.png'

import { ReactComponent as UnicityScience } from './assets/img/unimatescience.svg'
import { ReactComponent as UnicityScienceMobile } from './assets/img/unimatescience-mobile.svg'
import StoreSplash from '@Stores/StoreSplash'
import StoreCountry from '@Stores/StoreCountry'
import { dictionary, plainDictionary } from '@Components/GlobalHelpers'
import { isNothing, isString } from '@Components/utils/Utils'
import { language } from '@Language'
import Tag from 'antd/es/tag'
import { observer } from 'mobx-react'
import Carousel, { Dots } from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'
import Card from '@Components/Layouts/Card/Card'
import CardBody from '@Components/Layouts/Card/CardBody'
import { useState } from 'react'
import { ReactComponent as DotActive } from './assets/img/dot-active.svg'
import { ReactComponent as DotInActive } from './assets/img/dot-inactive.svg'
import { isDesktop } from '@Bowser'
import FeelGreatCover from './assets/img/feelgreat/feelgreat-cover.png'
import FeelGreatCoverTablet from './assets/img/feelgreat/feelgreat-cover-tablet.png'
import FeelGreatCoverMobile from './assets/img/feelgreat/feelgreat-cover-mobile.png'
import FeelGreatProduct1 from './assets/img/feelgreat/feelgreat-product1.svg'
import FeelGreatProduct2 from './assets/img/feelgreat/feelgreat-product2.svg'
import FeelGreatProduct3 from './assets/img/feelgreat/feelgreat-product3.svg'
import FeelGreatProduct1Tablet from './assets/img/feelgreat/feelgreat-product1-tablet.svg'
import FeelGreatProduct2Tablet from './assets/img/feelgreat/feelgreat-product2-tablet.svg'
import FeelGreatProduct3Tablet from './assets/img/feelgreat/feelgreat-product3-tablet.svg'
import FeelGreatProduct1Mobile from './assets/img/feelgreat/feelgreat-product1-mobile.svg'
import FeelGreatProduct2Mobile from './assets/img/feelgreat/feelgreat-product2-mobile.svg'
import FeelGreatProduct3Mobile from './assets/img/feelgreat/feelgreat-product3-mobile.svg'
import UnimateFeelgreatProductDescription from './assets/img/feelgreat/unimate-description.png'
import BioslifeFeelgreatProductDescription from './assets/img/feelgreat/bioslife-description.png'

const GenerateTags = item => {
    const TagBody = feature => {
        let color, value, key

        if (isString(feature)) {
            key = feature
        }

        const c = StoreCountry.Country2()
        const globalPrefix = `u_all_tag_`
        const nativePrefix = `u_${c}_tag_`

        const keyTemplate = {
            outOfStock: {
                color: {
                    native: `${nativePrefix}color_out_of_stock`,
                    global: `${globalPrefix}color_out_of_stock`
                },
                value: {
                    native: `${nativePrefix}value_out_of_stock`,
                    global: `${globalPrefix}value_out_of_stock`
                }
            },
            backOrder: {
                color: {
                    native: `${nativePrefix}color_back_order`,
                    global: `${globalPrefix}color_back_order`
                },
                value: {
                    native: `${nativePrefix}value_back_order`,
                    global: `${globalPrefix}value_back_order`
                }
            }
        }

        const keyOutOfStockColor = plainDictionary(keyTemplate.outOfStock.color.native) ? keyTemplate.outOfStock.color.native : keyTemplate.outOfStock.color.global
        const keyOutOfStockValue = plainDictionary(keyTemplate.outOfStock.value.native) ? keyTemplate.outOfStock.value.native : keyTemplate.outOfStock.value.global
        const keyBackOrderColor = plainDictionary(keyTemplate.backOrder.color.native) ? keyTemplate.backOrder.color.native : keyTemplate.backOrder.color.global
        const keyBackOrderValue = plainDictionary(keyTemplate.backOrder.value.native) ? keyTemplate.backOrder.value.native : keyTemplate.backOrder.value.global

        switch (key) {
            case 'outofstock':
                color = dictionary(keyOutOfStockColor)
                value = dictionary(keyOutOfStockValue)
                break
            case 'backorder':
                color = dictionary(keyBackOrderColor)
                value = dictionary(keyBackOrderValue)
                break
            default:
                const keyt = {
                    native: feature.native,
                    global: feature.english
                }

                color = feature.color

                if (isNothing(color)) {
                    color = '#B4B4B4'
                }

                value = language.isEnglish() ? feature.english : feature.native ? feature.native : feature.english

                break
        }

        return (
            <Tag color={color} style={{ marginBottom: '5px' }} key={key}>
                {value}
            </Tag>
        )
    }

    const tags = []

    if (item) {
        item.system_tags.map((v, k) => {
            if (v) {
                const featureEnglish = v.text.english
                const featureNative = v.text.native
                if (featureEnglish || featureNative) {
                    tags.push(<TagBody english={featureEnglish} native={featureNative} color={v.style.background_color} key={k} />)
                }
            }
        })

        item.tags.map((v, k) => {
            if (v) {
                const featureEnglish = v.text.english
                const featureNative = v.text.native
                if (featureEnglish || featureNative) {
                    tags.push(<TagBody english={featureEnglish} native={featureNative} color={v.style.background_color} key={k} />)
                }
            }
        })
    }

    return tags
}

export const SplashBlockType1 = observer(({ title, cover, productImage, itemCode, packet, flow, currency = 'AUD' }) => {
    return (
        <div className="splash--covers">
            <div className="splash--unimate" style={{ backgroundImage: `url(${cover})` }} />
            <div className="splash--suggest-product">
                <div className="splash--suggest-product-image">{productImage}</div>
                <div className="splash--suggest-product-title">
                    <h2>{title}</h2>
                    <div className="splash--suggest-product-price">
                        <div>
                            <span className="gray">{currency}</span> {StoreSplash.GetItemPrice(itemCode)}
                        </div>
                        &nbsp;<span className="gray">|</span>&nbsp;
                        <div>
                            {packet} <span className="gray">Packets</span>
                        </div>
                    </div>
                    <div className="splash--suggest-product-tags">{GenerateTags(StoreSplash.getProduct(itemCode))}</div>
                </div>
            </div>
        </div>
    )
})

export const SplashBlockType1r = observer(({ title, cover, productImage, itemCode, packet, flow, currency = 'AUD' }) => {
    return (
        <div className="splash--covers">
            <div className="splash--suggest-product">
                <div className="splash--suggest-product-image">{productImage}</div>
                <div className="splash--suggest-product-title">
                    <h2>{title}</h2>
                    <div className="splash--suggest-product-price">
                        <div>
                            <span className="gray">{currency}</span> {StoreSplash.GetItemPrice(itemCode)}
                        </div>
                        &nbsp;<span className="gray">|</span>&nbsp;
                        <div>
                            {packet} <span className="gray">Packets</span>
                        </div>
                    </div>
                    <div className="splash--suggest-product-tags">{GenerateTags(StoreSplash.getProduct(itemCode))}</div>
                </div>
            </div>
            <div className="splash--unimate" style={{ backgroundImage: `url(${cover})` }} />
        </div>
    )
})

export const SplashBlockType1f = observer(({ cover, title, content }) => {
    return (
        <div className="splash--covers feelgreat--style">
            <div className="splash--unimate feelgreat--cover" style={{ backgroundImage: `url(${cover})` }}>
                <div>
                    {title}
                    {content}
                </div>
            </div>
        </div>
    )
})

export const SplashBlockType2 = observer(({ children, totalItems, isMobile }) => {
    const [value, setValue] = useState(0)

    const onChange = e => {
        setValue(e)
    }

    return (
        <div className="splash--ideal-box-wrapper">
            {isMobile && (
                <>
                    <Carousel onChange={onChange} animationSpeed={100} value={value}>
                        {children}
                    </Carousel>
                    <Dots
                        className="splash--ideal-box-dots"
                        value={value}
                        onChange={onChange}
                        thumbnails={[...new Array(totalItems)].map((v, k) => {
                            return value === k ? <DotActive /> : <DotInActive />
                        })}
                    />
                </>
            )}
            {isMobile === false && children}
        </div>
    )
})

export const SplashBlockType2f = observer(({ children, totalItems }) => {
    const [value, setValue] = useState(0)

    const onChange = e => {
        setValue(e)
    }

    return (
        <div className="splash--ideal-box-wrapper feelgreat--style">
            <Carousel onChange={onChange} animationSpeed={100} value={value}>
                {children}
            </Carousel>
            <Dots
                className="splash--ideal-box-dots feelgreat--style"
                value={value}
                onChange={onChange}
                thumbnails={[...new Array(totalItems)].map((v, k) => {
                    return value === k ? <DotActive /> : <DotInActive />
                })}
            />
        </div>
    )
})

export const SplashBlockType2Contentf = observer(({ title, productImage, itemCode, packet, currency = 'AUD' }) => {
    return (
        <div className="splash--covers feelgreat--style">
            <div className="splash--suggest-product feelgreat--style">
                <div className="splash--suggest-product-image">
                    <img src={productImage} />
                </div>
                <div className="splash--suggest-product-title feelgreat--style">
                    <h2 className="text-center">{StoreSplash.getProduct(itemCode).item_name[language.isEnglish() ? 'english' : 'native']}</h2>
                    <div className="splash--suggest-product-price" style={{ justifyContent: 'center' }}>
                        <div>
                            <span className="gray">{currency}</span> {StoreSplash.GetItemPrice(itemCode)}
                        </div>
                        &nbsp;<span className="gray">|</span>&nbsp;
                        <div>
                            {packet} <span className="gray">Packets</span>
                        </div>
                    </div>
                    <div className="splash--suggest-product-tags text-center">{GenerateTags(StoreSplash.getProduct(itemCode))}</div>
                </div>
            </div>
        </div>
    )
})

export const SplashBlockType3 = props => {
    return (
        <div className={`splash--product-description ${props.product}`}>
            <div className={`splash--product-description-cover ${props.product}`} style={{ backgroundImage: `url(${props.cover})` }}></div>
            <div className={`splash--product-description-content ${props.product}`}>
                {props.content}
                {props.extraElement && props.extraElement}
            </div>
        </div>
    )
}

export const SplashBlockType4 = observer(({ title, cover, content, isMobile, flow }) => {
    return (
        <div className="splash--unicity-science">
            <h3>{title}</h3>
            <div className="splash--unicity-science-content-wrapper">
                <div className="splash--unicity-science-cover">
                    {isMobile && cover.mobile}
                    {!isMobile && cover.desktop}
                </div>
                <div className="splash--unicity-science-content">{content}</div>
            </div>
        </div>
    )
})
export const SplashBlockType4r = observer(({ title, cover, content, isMobile, flow }) => {
    return (
        <div className="splash--unicity-science reverse">
            <h3>{title}</h3>
            <div className="splash--unicity-science-content-wrapper">
                <div className="splash--unicity-science-content">{content}</div>
                <div className="splash--unicity-science-cover">
                    {isMobile && cover.mobile}
                    {!isMobile && cover.desktop}
                </div>
            </div>
        </div>
    )
})

export const SplashBlockType5 = observer(({ ingredient, className }) => {
    const SplashNutritionalValuesBar = ({ titleL, titleR, style, underLineStyle = {} }) => {
        return (
            <div className="splash--nutritional-bar" style={style ? style : {}}>
                <div className="splash--nutritional-bar-content">
                    <div>{titleL}</div>
                    <div>{titleR}</div>
                </div>
                <hr style={underLineStyle} />
            </div>
        )
    }

    const SplashNutritionalValuesIngredient = ({ titleL, titleR, showBorderBottom, showUnderLine, style }) => {
        return (
            <>
                <div className={`splash--nutritional-ingredient ${showBorderBottom ? 'border-bottom' : ''}`} style={style ? style : {}}>
                    <div className="splash--nutritional-ingredient-content">
                        <div className="gray">{titleL}</div>
                        <div className="gray">{titleR}</div>
                    </div>
                </div>
                {showUnderLine && <hr />}
            </>
        )
    }

    const SplashNutritionalBody = props => {
        return (
            <>
                <h3>{ingredient.fact}</h3>
                <p className="gray">{ingredient.description}</p>
                {ingredient.valuesBar.map((v, k) => (
                    <SplashNutritionalValuesBar key={k} titleL={v.titleL} titleR={v.titleR} style={v.style || {}} underLineStyle={v.underLineStyle} />
                ))}
                {ingredient.valuesIngredient.map((v, k) => (
                    <SplashNutritionalValuesIngredient key={k} titleL={v.titleL} titleR={v.titleR} style={v.style || {}} showBorderBottom={k === 0 ? true : false} showUnderLine={v.showUnderLine} />
                ))}
            </>
        )
    }

    const SplashNutritionalMobile = props => {
        return (
            <>
                <h3>{ingredient.title}</h3>
                <Card className="splash--nutritional-card">
                    <div className="splash--nutritional-card-cover" style={{ backgroundImage: `url(${ingredient.cover})` }}></div>
                    <CardBody>
                        <SplashNutritionalBody />
                    </CardBody>
                </Card>
                <div className="splash--nutritional-other">
                    <div>
                        {ingredient.other.title}{' '}
                        <span className="text-muted" style={{ fontWeight: 400 }}>
                            {ingredient.other.content}
                        </span>
                    </div>
                </div>
            </>
        )
    }

    const SplashNutritionalDesktop = props => {
        return (
            <Card className="splash--nutritional-card">
                <div className="splash--nutritional-card-cover" style={{ backgroundImage: `url(${ingredient.cover})` }}></div>
                <div>
                    <h3>{ingredient.title}</h3>
                    <CardBody>
                        <SplashNutritionalBody />
                    </CardBody>
                    <div className="splash--nutritional-other">
                        <div>{ingredient.other.title}&nbsp;</div>
                        <div className="text-muted">{ingredient.other.content}</div>
                    </div>
                </div>
            </Card>
        )
    }

    return (
        <div className={`splash--nutritional ${className}`}>
            <div className="desktop">
                <SplashNutritionalDesktop />
            </div>
            <div className="mobile">
                {' '}
                <SplashNutritionalMobile />
            </div>
        </div>
    )
})

export const UnimateContents = {
    product: {
        cover: Unimate,
        title: 'UNIMATE YERBA MATE BLEND',
        itemCode: '29408',
        packets: '30',
        productImage: <img src={UnimatePack} />,
        flow: ''
    },
    productDescription: [
        {
            cover: UnimateProductDescription,
            content: (
                <div>
                    <h3>PRODUCT DESCRIPTION</h3>
                    <p>Some days you are on top of the world—you get things done and you feel great doing them. Unimate helps every day to be like that.</p>
                    <p>
                        Carefully extracted from the highest quality yerba mate leaves are concentrated and extracted through a patented 5-step process. Unimate contains up to 375 times more chlorogenic acids than other commercially available yerba
                        mate drinks. With benefits that energize, boost athletic performance, enhance mood, and mental focus.{' '}
                    </p>
                    <p>The result is an amazing improvement of an elevated quality of life.</p>
                </div>
            )
        },
        {
            cover: UnimateIngredient,
            content: (
                <div>
                    <h3 className="text-center">INGREDIENTS & BENEFITS</h3>
                    <p>
                        Yerba mate is an herb traditionally used in a tea-like beverage that is consumed socially in South America.It is known to produce a calming effect while at the same time increasing energy and focus. The proprietary extract in
                        Unimate amplifies these and other effects to create the totally unique Unimate experience. Yerba mate is a natural source of:
                    </p>
                    <ul>
                        <li>
                            Chlorogenic acids - Promotes neuroprotective gene expression and has an antioxidant effect in the brain (JNK3, P38, NQO1). Promotes feelings of well-being. Supports healthy blood pressure and glucose levels. Promotes
                            weight loss.
                        </li>
                        <li>Mate saponins - Supports healthy triglycerides and the body’s ability to burn fat.</li>
                        <li>Theobromine - Supports healthy blood pressure and cholesterol levels, promotes feeling of calm, reduced mental fatigue, and improves focus.</li>
                    </ul>
                </div>
            )
        }
    ],
    idealBlockContent: [
        {
            title: 'RECOMMENDED USE',
            content: 'Mix one packet with 14-16 oz. of water. Use once daily or as desired. Drink with hot or cold water and enjoy!'
        },
        {
            title: 'IDEAL FOR PEOPLE WHO…',
            content:
                'Unimate provides the energy and mental focus when you need it the most. Providing energy to keep up with your kids, increase performance and stamina, or the focus needed for those long study days; Unimate will get you through it all.'
        },
        {
            title: 'CONTENTS',
            content: (
                <ul style={{ listStyleType: '-' }}>
                    <li>Serving Size: 1 packet (4.45g)</li>
                    <li>Servings per package: 30</li>
                </ul>
            )
        }
    ],
    unicityScience: {
        title: 'UNICITY SCIENCE',
        cover: {
            mobile: <UnicityScienceMobile />,
            desktop: <UnicityScience />
        },
        content: (
            <div>
                Unimate is uniquely powerful thanks to a unique 5-step patented process. Beginning with handpicking and fire-roasting and followed by the extraction of active biological molecules, concentration and purification, this process results
                in up to 375 times more chlorogenic acids than other commercially available yerba mate drinks. More information on our scientific work on Unimate and other Unicity products can be found at{' '}
                <a href="https://www.unicityscience.org/" target="_blank">
                    unicityscience.org
                </a>
                .
            </div>
        )
    },
    ingredient: {
        title: 'NUTRITIONAL VALUES',
        fact: 'SUPPLEMENT FACTS',
        description: '30 Packets per container',
        cover: Unimate2,
        valuesBar: [
            {
                titleL: 'Serving size',
                titleR: '1 Packet (13.8 g)'
            },
            {
                titleL: 'Amount per serving',
                titleR: '1 % Daily Value',
                style: { marginBottom: 15 }
            }
        ],
        valuesIngredient: [
            {
                titleL: 'Unimate',
                titleR: '3 g',
                showBorderBottom: true
            },
            {
                titleL: '(Green Mate Leaf Extract) Powder',
                titleR: '',
                style: { marginTop: 5 }
            }
        ],
        other: {
            title: 'Other Ingredients:',
            content: 'Natural Flavors, Citric Acid, and Stevia Extract'
        }
    }
}

export const UnimateLemonContents = {
    product: {
        cover: UnimateLemon2,
        title: 'UNIMATE LEMON',
        itemCode: '33331',
        packets: '30',
        productImage: <UnimateLemon />,
        flow: 'reverse'
    },
    productDescription: [
        {
            cover: UnimateLemonProductDescription,
            content: (
                <div>
                    <h3>PRODUCT DESCRIPTION</h3>
                    <p>Some days you are on top of the world—you get things done and you feel great doing them. Unimate helps every day to be like that. </p>
                    <p>
                        Carefully extracted from the highest quality yerba mate leaves are concentrated and extracted through a patented 5-step process. Unimate contains up to 375 times more chlorogenic acids than other commercially available yerba
                        mate drinks. With benefits that energize, boost athletic performance, enhance mood, and mental focus.{' '}
                    </p>
                    <p>The result is an amazing improvement of an elevated quality of life.</p>
                </div>
            )
        },
        {
            cover: UnimateProductDescriptionDesktop,
            content: (
                <div>
                    <h3 className={`${isDesktop() ? 'text-center' : ''}`}>WHY UNIMATE LEMON</h3>
                    <p>Unimate is a sugar-free yerba mate drink that will help you manage your appetite and reach your goals with enhanced mental clarity and an improved mood.</p>
                    <ul>
                        <li>
                            Unimate is high in chlorogenic acids (those feel-good elements also found in coffee) to give your brain the willpower to achieve your goals. Our yerba mate contains up to 10 times the amount of chlorogenic acids found in a
                            typical cup of coffee and about as much naturally occurring caffeine.
                        </li>
                        <li>Unimate is an incredibly pure, highly-concentrated yerba mate powder that supports cognitive function, feelings of well-being, and endurance.</li>
                        <li>Delicious and refreshing, Unimate comes in two new flavors, so you can choose your favorite way to fend off hunger and feel great!</li>
                    </ul>
                </div>
            )
        }
    ],
    idealBlockContent: [
        {
            title: 'RECOMMENDED USE',
            content: 'All the products in The Feel Great Pack will help you feel great, look great and perform great. See detailed recommended use for each product below.'
        },
        {
            title: 'IDEAL FOR PEOPLE WHO…',
            content: (
                <ul>
                    <li>Those wanting to improve their overall health.</li>
                    <li>Those who understand that the modern diet leaves too many nutrients out of the foods we eat.</li>
                    <li>Those wanting to build their product experience and share it with others.</li>
                    <li>Anyone who knows there is so much more to life!</li>
                </ul>
            )
        },
        {
            title: 'CONTENTS',
            content: (
                <ul style={{ listStyleType: '-' }}>
                    <li>Unicity Balance Serving Size: 1 packet (2 per day)</li>
                    <li>Unimate Lemon Serving Size: 1 packet</li>
                </ul>
            )
        }
    ],
    unicityScience: {
        title: <h3>UNICITY SCIENCE</h3>,
        cover: {
            mobile: <UnicityScienceMobile />,
            desktop: <UnicityScience />
        },
        content: (
            <div>
                More information on our scientific work on Unimate and other Unicity products can be found at{' '}
                <a href="https://www.unicityscience.org/" target="_blank">
                    unicityscience.org
                </a>
                .
            </div>
        ),
        flow: 'reverse'
    },
    ingredient: {
        title: 'NUTRITIONAL VALUES',
        fact: 'SUPPLEMENT FACTS',
        description: (
            <>
                Serving Size 1 packet (6.1 g)
                <br />
                Servings Per Container 30 packets
            </>
        ),
        cover: NutritionalImage,
        valuesBar: [
            {
                titleL: '',
                titleR: ''
            },
            {
                titleL: '',
                titleR: <span style={{ color: '#003B6F' }}>Amount Per Serving % DV</span>,
                style: { marginBottom: 15 },
                underLineStyle: { height: 1 }
            }
        ],
        valuesIngredient: [
            {
                titleL: (
                    <>
                        Unimate
                        <br />
                        (Green Mate Leaf Extract) Powder
                    </>
                ),
                titleR: '3 g',
                showUnderLine: true
            },
            {
                titleL: 'Percent Daily Value not established.',
                titleR: '',
                style: { marginTop: 5 }
            }
        ],
        other: {
            title: 'Other Ingredients:',
            content: 'Erythritol, Citric Acid, Sodium Gluconate, Natural Flavors, Stevia Extract, Sucralose.'
        }
    }
}

export const FeelGreatPackContents = {
    product: [
        {
            itemCode: '34304',
            title: 'FEEL GREAT 1',
            productImage: {
                desktop: FeelGreatProduct1,
                tablet: FeelGreatProduct1Tablet,
                mobile: FeelGreatProduct1Mobile
            }
        },
        {
            itemCode: '34305',
            title: 'FEEL GREAT 2',
            productImage: {
                desktop: FeelGreatProduct2,
                tablet: FeelGreatProduct2Tablet,
                mobile: FeelGreatProduct2Mobile
            }
        },
        {
            itemCode: '34306',
            title: 'FEEL GREAT 3',
            productImage: {
                desktop: FeelGreatProduct3,
                tablet: FeelGreatProduct3Tablet,
                mobile: FeelGreatProduct3Mobile
            }
        }
    ],
    productDescription: {
        title: <h3>FEEL GREAT NOW</h3>,
        content: <p>It's time for a fresh start. It's time to feel better than you've felt in years. It's time to Feel Great.</p>,
        cover: {
            desktop: FeelGreatCover,
            tablet: FeelGreatCoverTablet,
            mobile: FeelGreatCoverMobile
        }
    },
    productDescription2: [
        {
            cover: UnimateFeelgreatProductDescription,
            type: 'feelgreat-unimate',
            content: (
                <div>
                    <h3>WHY UNIMATE ?</h3>
                    <p>Unimate is a highly concentrated yerba mate drink that will help you focus on your goals with an improved mood and heightened mental stamina. </p>
                    <ul>
                        <li>Yerba mate has been used in South America for thousands of years. Its unique blend of plant compounds is known to improve mood, heighten mental clarity, and suppress appetite.</li>
                        <li>Unimate is a potent source of up to 10x the chlorogenic acid content of a cup of coffee. Chlorogenic acid: the “get-up-and-go” compound in coffee.</li>
                        <li>Unimate is sugar free.</li>
                    </ul>
                </div>
            )
        },
        {
            cover: BioslifeFeelgreatProductDescription,
            type: 'feelgreat-bioslife',
            content: (
                <div>
                    <h3>WHY BIOS LIFE S ?</h3>
                    <p>
                        Bios Life S is a revolutionary fat burning product; providing the first scientific breakthrough in fat loss in over 30 years. Containing natural, proprietary ingredients, Bios Life Slim™ helps your body naturally regulate the
                        amount of fat stored.
                    </p>
                    <h3>FAT LOSS | ENERGY | SCIENCE</h3>
                    <p>
                        Bios Life Slim™ contains proprietary ingredients that help your body naturally and safely reduce the amount of fat it stores. Bios Life Slim™ not only helps your body store less fat but also allows existing fat to be burned
                        away-naturally. No starving, no jitters, no stimulants!
                    </p>
                    <p>Bios Life Slim™ contains f the ingredients of Bios Life therefore, it also provides the following benefits:</p>
                    <ul>
                        <li>Increases energy</li>
                        <li>Reduces glycemic index of your foods you eat</li>
                        <li>Maintain healthy cholesterol levels</li>
                        <li>Balances blood sugar</li>
                        <li>Improves lipid profiles</li>
                        <li>Regulates bowel function</li>
                    </ul>
                </div>
            )
        }
    ]
}
