import { createBrowserHistory } from 'history';
import { wrapHistory } from 'oaf-react-router';

const history = createBrowserHistory()

const settings = {
    shouldHandleAction: (previousLocation, nextLocation) => {
        // Inspect/compare nextLocation and/or previousLocation.
        if (previousLocation !== nextLocation) {
            // always go to top when location is changed
            window.scroll(0, 0)
        }
        return false // false if you don't want scroll restoration.
    },
    disableAutoScrollRestoration: true,
}

wrapHistory(history, settings)

export default history