import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { If, Then, Else } from 'react-if'


const CardHeader = observer(

    class CardHeader extends Component {

        constructor(props) {
            super(props)
        }

        render() {

            const props ={...this.props}

            const classProps = `card-header ${props.className ? props.className : ''} text-center`
            

            return (<div className={classProps}>
                <If condition={props.children ? true : false}>
                    <Then>{props.children}</Then>
                    <Else>
                        <h4>{props.title}</h4>
                    </Else>
                </If>
            </div>)
        }
    }

)

export default CardHeader