import { observer } from 'mobx-react'
import './assets/splash-content.scss'
import { storeElement } from '@Stores/StoreElement'
import { SplashBlockType1r, SplashBlockType2, SplashBlockType3, SplashBlockType4r, SplashBlockType5, UnimateContents, UnimateLemonContents } from './SplashItems'

const SplashUnimateLemonContent = observer(props => {

    const SplashBlockType2Content = ({ title, content, page }) => {

        return <div className="splash--ideal-box">
            <div className="splash--ideal-box-title">
                {title}
            </div>
            <div className="splash--ideal-box-content">
                {content}
            </div>
        </div>
    }

    return <>
        <SplashBlockType1r
            title={UnimateLemonContents.product.title}
            itemCode={UnimateLemonContents.product.itemCode}
            cover={UnimateLemonContents.product.cover}
            productImage={UnimateLemonContents.product.productImage}
            flow={UnimateLemonContents.product.flow}
            packet={UnimateLemonContents.product.packets}
        />
        <SplashBlockType2
            isMobile={storeElement.isMobile(window.innerWidth < 1366)}
            totalItems={UnimateLemonContents.idealBlockContent.length}>
            {UnimateLemonContents.idealBlockContent.map((v, k) => {
                return <SplashBlockType2Content title={v.title} content={v.content} key={k} page={k} />
            })}
        </SplashBlockType2>
        {UnimateLemonContents.productDescription.map((v, k) => (
            <SplashBlockType3
                key={`block-type3-${k}`}
                cover={v.cover}
                content={v.content}
                product="unimate-lemon"
            />
        ))}
        <SplashBlockType4r
            title={UnimateLemonContents.unicityScience.title}
            cover={UnimateLemonContents.unicityScience.cover}
            content={UnimateLemonContents.unicityScience.content}
            isMobile={storeElement.isMobile()}
            flow="reverse"
        />
        <SplashBlockType5 ingredient={UnimateLemonContents.ingredient} className="unimate-lemon" />
    </>
})



export default SplashUnimateLemonContent