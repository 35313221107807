import React, { Component, useState, useEffect } from 'react'
import { observer, useObserver } from 'mobx-react'
import CardMenu from './CardMenu'



export const CardHeader = props => {

    const [className, setClassName] = useState('')

    const [classProps] = useState(`card-header`)

    const [showMenu, setShowMenu] = useState(false)

    const onClick = () => props.onClick || function () { }

    useEffect(() => {

        setClassName(props.className)

    }, [props.className])
    
    useEffect(() => {

        setShowMenu(props.showMenu)

    }, [props.showMenu])

    return useObserver(() => <>
       
        <CardMenu menu={props.menu} showMenu={showMenu} {...props} />
        <div className={`${classProps} ${props.className}`} onClick={onClick()}>{props.children || props.title}</div>
    </>)
}

export const CardFooter = props => {

    const className = props.className || ''

    const classProps = `card-footer ${className}`

    const onClick = () => props.onClick || function () { }

    return useObserver(() => <div className={classProps} onClick={() => onClick()}>{props.children}</div>)
}


const Card = props => {

  

    const onClick = () => props.onClick || function () { }

    return useObserver(() => <div className={`card ${props.className ? props.className : ''}`} style={props.style ? props.style: {}} onClick={() => onClick()}>
        {props.children}
    </div>)
}

export default Card