import { getParameterByName, getQueryString, hasQueryString } from '@Components/configs/ConfigsHeader'
import { loge, logn } from '@Components/utils/PikaLog'
import Raven from '@Raven'
import StoreSplash from '@Stores/StoreSplash'
import { autorun } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

const SplashProductSource = observer(props => {
    useEffect(() => {
        GetDefaultProduct()
        GetDefaultProductFeelGreat()
        autorun(r => {
            if (StoreSplash.ProductsReady() && !StoreSplash.HasSplashKit() && StoreSplash.IsSplashReady === false) {
                StoreSplash.IsSplashReady = true
                GetSplashKit(getParameterByName('ref_id'))
                r.dispose()
            }
        })
    }, [])

    const GetDefaultProduct = () => {
        let status = ['A']
        if (StoreSplash.IsStatusM()) {
            status = ['M']
        }

        Promise.all(
            status.map(type => {
                Raven.getProductSource2('shop', type)
                    .then(response => {
                        processProductSource(response, type)
                        logn('✅ 🐉 Successfully load product source from database.')
                    })
                    .catch(response => {
                        loge('❌ 🐉 Failed to connect our product source, please check getProducts API.')
                        const emergencySource = localStorage.getItem(`product-source-${type}`)
                        if (emergencySource) {
                            processProductSource(JSON.parse(emergencySource))
                            logn('🚨 🐉 Current product source was restored from localStorage in case of incident problem.')
                        } else {
                            loge('❌ 🐉 Failed to restored product source from localStorage, our website will crash.')
                        }
                    })
            })
        )
    }

    const GetDefaultProductFeelGreat = () => {
        let status = ['A']
        if (StoreSplash.IsStatusM()) {
            status = ['M']
        }

        Promise.all(
            status.map(type => {
                Raven.getProductSource2('feelGreat', type)
                    .then(response => {
                        processProductSource(response, type, 'feelGreat')
                        logn('✅ 🐉 Successfully load product source from database status:', type)
                    })
                    .catch(response => {
                        loge('❌ 🐉 Failed to connect our product source, please check getProducts API.')
                        const emergencySource = localStorage.getItem(`product-source-${type}`)
                        if (emergencySource) {
                            processProductSource(JSON.parse(emergencySource))
                            logn('🚨 🐉 Current product source was restored from localStorage in case of incident problem.')
                        } else {
                            loge('❌ 🐉 Failed to restored product source from localStorage, our website will crash.')
                        }
                    })
            })
        )
    }

    const GetSplashKit = referrenceId => {
        if (referrenceId) {
            Raven.getSplashKit(referrenceId)
                .then(response => {
                    StoreSplash.SetSplashData(response)
                    if (StoreSplash.OrderTerms.Associate === null || StoreSplash.OrderTerms.PreferredCustomer === null) {
                        if (StoreSplash.IsPaymentPage() === true) {
                            StoreSplash.CalculateOrder('Associate')
                        }
                    }
                    //
                })
                .catch(error => {
                    console.log('error', error)
                    loge(`❌ 🐉 Failed GetSplashKit`)
                })
        } else {
            const isFeelGreat = hasQueryString('member_id') && hasQueryString('template')

            if (isFeelGreat) {
                const memberID = getQueryString().member_id
                Raven.getSplashFeelGreat(memberID)
                    .then(response => {
                        StoreSplash.SetSplashData(response)
                        if (StoreSplash.OrderTerms.Associate === null || StoreSplash.OrderTerms.PreferredCustomer === null) {
                            if (StoreSplash.IsPaymentPage() === true) {
                                StoreSplash.CalculateOrder('Associate')
                            }
                        }
                    })
                    .catch(error => {
                        console.log('ERROR getSplashFeelGreat', error)
                    })
            }
        }
    }

    const processProductSource = (response, status, type) => {
        StoreSplash.SetProductSource(response.items, status, type)
        StoreSplash.warehouse = response.warehouse
    }

    return <div></div>
})

export default SplashProductSource
