import { language } from '@Language'
import { storeElement } from '@Stores/StoreElement'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import './splash.scss'
import '../enroll/enrollment.scss'
import SplashDescription from './SplashDescription'
import SplashContent from './SplashContent'
import SplashProducts from './SplashProducts'
import StoreSplash from '@Stores/StoreSplash'
import SplashContact from './SplashContact'
import { Case, Else, If, Switch, Then } from 'react-if'
import { getQueryString, hasQueryString } from '@Components/configs/ConfigsHeader'
import ResizeObserver from 'react-resize-observer'
import { useHistory, useParams } from 'react-router-dom'
import { autorun } from 'mobx'
import Spinner from '@Components/Spinner'
import './assets/splash-content.scss'
import { storeBowser } from '@Stores/StoreBowser'

const Splash = observer(props => {
    const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight })
    const params = useParams()
    const history = useHistory()

    useEffect(() => {
        if (hasQueryString('sleepy')) {
            StoreSplash.useSleepy = true
        }
        if (window.innerWidth < 768) {
            storeElement.setMobileMode(true)
        } else {
            storeElement.setMobileMode(false)
        }
        window.scrollTo(0, 0)

        autorun(r => {
            if (StoreSplash.HasSplashKit()) {
                if (getQueryString().stateRefId) {
                    StoreSplash.GetEnrollState(getQueryString().stateRefId)
                        .then(response => {
                            const refID = getQueryString().ref_id
                            history.push(`${StoreSplash.UrlRootPath()}/${refID ? `?ref_id=${refID}` : window.location.search}`)
                        })
                        .catch(error => {
                            console.log('error', error)
                        })
                    r.dispose()
                } else {
                    r.dispose()
                }
            }
        })

        if (/shipping-detail/.test(window.location.href)) {
            StoreSplash.InitStep('shipping-detail')
        } else if (/payment/.test(window.location.href)) {
            StoreSplash.InitStep('payment')
        } else {
            StoreSplash.InitStep()
        }
    }, [])

    return (
        <SplashWrapper>
            {StoreSplash.IsSplashReady === true && (
                <>
                    <ResizeObserver
                        onResize={rect => {
                            if (dimensions.width !== rect.width) {
                                if (rect.width < 768) {
                                    storeElement.setMobileMode(true)
                                } else {
                                    storeElement.setMobileMode(false)
                                }
                                setDimensions(rect)
                            }
                        }}
                    />
                    <Switch>
                        <Case condition={StoreSplash.IsFirstPage()}>
                            {/* <h2>{language.listen('splash_shopping_cart')}</h2> */}
                            <ProductListBlockWrapper>
                                <SplashProducts dimensions={dimensions} />
                            </ProductListBlockWrapper>
                            <SplashDescription />
                            <SplashContent dimensions={dimensions} item={params.item} />
                        </Case>
                        <Case condition={StoreSplash.IsShippingDetailPage()}>
                            <If condition={StoreSplash.getReferralId() === null}>
                                <Then>
                                    <div style={{ minHeight: 500, width: '100%', display: 'flex', alignItems: 'center' }}>
                                        <Spinner />
                                    </div>
                                </Then>
                                <Else>
                                    <SplashContact />
                                </Else>
                            </If>
                        </Case>
                        <Case condition={StoreSplash.IsPaymentPage()}>
                            <If condition={StoreSplash.getReferralId() === null}>
                                <Then>
                                    <div style={{ minHeight: 500, width: '100%', display: 'flex', alignItems: 'center' }}>
                                        <Spinner />
                                    </div>
                                </Then>
                                <Else>
                                    {!storeBowser.isDesktop() && <h2 style={!storeBowser.isDesktop() ? { paddingBottom: 0 } : {}}>{language.listen('order_summary')}</h2>}
                                    <ProductListBlockWrapper>
                                        <SplashProducts dimensions={dimensions} />
                                    </ProductListBlockWrapper>
                                    <SplashDescription />
                                </Else>
                            </If>
                        </Case>
                    </Switch>
                </>
            )}
        </SplashWrapper>
    )
})

const ProductListBlockWrapper = observer(props => {
    return (
        <div className="splash--product-wrapper">
            <div className={`splash--product-list-wrapper ${storeElement.isMobile() ? 'list-mobile' : StoreSplash.IsPaymentPage() ? 'list-payment-desktop' : 'list-desktop'}`}>{props.children}</div>
        </div>
    )
})

const SplashWrapper = observer(props => {
    return <div className="splash--wrapper">{props.children}</div>
})

export default Splash
