import { observer } from 'mobx-react'
import './assets/splash-content.scss'
import { storeElement } from '@Stores/StoreElement'
import { SplashBlockType1, SplashBlockType2, SplashBlockType3, SplashBlockType4, SplashBlockType5, UnimateContents } from './SplashItems'

const SplashUnimateContent = observer(props => {
    const SplashBlockType2Content = ({ title, content, page }) => {
        return (
            <div className="splash--ideal-box">
                <div className="splash--ideal-box-title">{title}</div>
                <div className="splash--ideal-box-content">{content}</div>
            </div>
        )
    }

    return (
        <>
            <SplashBlockType1
                title={UnimateContents.product.title}
                itemCode={UnimateContents.product.itemCode}
                content={UnimateContents.product.content}
                cover={UnimateContents.product.cover}
                productImage={UnimateContents.product.productImage}
                packet={UnimateContents.product.packets}
            />
            <SplashBlockType2 isMobile={storeElement.isMobile(window.innerWidth < 1366)} totalItems={UnimateContents.idealBlockContent.length}>
                {UnimateContents.idealBlockContent.map((v, k) => {
                    return <SplashBlockType2Content title={v.title} content={v.content} key={k} page={k} />
                })}
            </SplashBlockType2>
            {UnimateContents.productDescription.map((v, k) => (
                <SplashBlockType3 key={`block-type3-${k}`} cover={v.cover} content={v.content} />
            ))}
            <SplashBlockType4 title={UnimateContents.unicityScience.title} cover={UnimateContents.unicityScience.cover} content={UnimateContents.unicityScience.content} isMobile={storeElement.isMobile()} />
            <SplashBlockType5 ingredient={UnimateContents.ingredient} />
        </>
    )
})

export default SplashUnimateContent
