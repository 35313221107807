import { observer } from 'mobx-react'
import './assets/splash-content.scss'
import { FeelGreatPackContents, SplashBlockType1f, SplashBlockType2Contentf, SplashBlockType2f, SplashBlockType3 } from './SplashItems'
import { storeBowser } from '@Stores/StoreBowser'

const NZSplashFeelGreatPackContent = observer(props => {

    return <>
        {FeelGreatPackContents.productDescription2.map((v, k) => (
            <SplashBlockType3
                key={`block-type3-${k}`}
                cover={v.cover}
                content={v.content}
                product={v.type}
                currency={'NZD'}
            />
        ))}
    </>
})



export default NZSplashFeelGreatPackContent