import Card, { CardFooter } from '@Components/Layouts/Card/Card'
import CardBody from '@Components/Layouts/Card/CardBody'
import { language } from '@Language'
import { Button } from 'antd'
import { observer } from 'mobx-react-lite'
import './splash.scss'
import './assets/splash-description.scss'
import classnames from 'classnames'
import StoreSplash from '@Stores/StoreSplash'
import SplashPayWithCreditCard from './SplashCreditCard'
import Checkbox from 'antd/es/checkbox'
import { Else, If, Then } from 'react-if'
import { get } from 'lodash'
import Skeleton from 'antd/es/skeleton'
import { useHistory, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { getQueryString } from '@Components/configs/ConfigsHeader'
import DisplayDeliveryNote from '@Components/common/DisplayDeliveryNote'
import { dictionary } from '@Components/GlobalHelpers'
import { reaction } from 'mobx'
import StoreCountry from '@Stores/StoreCountry'

const TotalBlock = observer(props => {
    return (
        <SplashDescriptionItems type="label-content" noSpaceBottom>
            <SplashDescriptionItemsLabel
                title={
                    <If condition={StoreSplash.getReferralId() === null}>
                        <Then>
                            <Skeleton.Avatar active style={{ width: 120, height: 20 }} />
                        </Then>
                        <Else>{language.listen('splash_one_time_purchase')}</Else>
                    </If>
                }
                className="label-black"
            />
            <SplashDescriptionItemsContent>
                <If condition={StoreSplash.getReferralId() === null}>
                    <Then>
                        <Skeleton.Avatar active style={{ width: 90, height: 20 }} />
                    </Then>
                    <Else>
                        <If condition={StoreSplash.subscribeType === 'Associate'}>
                            <Then>
                                <s style={{ color: '#FFB74F' }}>
                                    {StoreSplash.GetTotalPrice('retail')} {StoreSplash.Currency()}
                                </s>
                            </Then>
                            <Else>
                                {StoreSplash.GetTotalPrice('wholesale')} {StoreSplash.Currency()}
                            </Else>
                        </If>
                    </Else>
                </If>
            </SplashDescriptionItemsContent>
        </SplashDescriptionItems>
    )
})

const SubscribedBlock = observer(props => {
    return (
        <SplashDescriptionItems type="label-content" noSpaceTopBottom>
            <SplashDescriptionItemsLabel
                title={
                    <If condition={StoreSplash.getReferralId() === null}>
                        <Then>
                            <Skeleton.Avatar active style={{ width: 100, height: 20 }} />
                        </Then>
                        <Else>{language.listen('splash_monthly_subscription')}</Else>
                    </If>
                }
                className="label-black"
            />
            <SplashDescriptionItemsContent>
                <If condition={StoreSplash.getReferralId() === null}>
                    <Then>
                        <Skeleton.Avatar active style={{ width: 120, height: 20 }} />
                    </Then>
                    <Else>
                        <>
                            {StoreSplash.GetTotalPrice()} {StoreSplash.Currency()}
                        </>
                    </Else>
                </If>
            </SplashDescriptionItemsContent>
        </SplashDescriptionItems>
    )
})

const SubscriptionDescriptionBlock = observer(props => {
    return (
        <SplashDescriptionItems type="label-content">
            <SplashDescriptionItemsLabel
                title={
                    <If condition={StoreSplash.getReferralId() === null}>
                        <Then>
                            <Skeleton.Avatar active style={{ width: 100, height: 20 }} />
                        </Then>
                        <Else>{StoreSplash.IsSubscribeMonthly() ? language.listen('splash_monthly_subscription') : language.listen('splash_one_time_purchase')}</Else>
                    </If>
                }
                className="label-black"
            />
            <SplashDescriptionItemsContent>
                <If condition={StoreSplash.getReferralId() === null}>
                    <Then>
                        <Skeleton.Avatar active style={{ width: 120, height: 20 }} />
                    </Then>
                    <Else>
                        <>
                            {StoreSplash.GetTotalPrice()} {StoreSplash.Currency()}
                        </>
                    </Else>
                </If>
            </SplashDescriptionItemsContent>
        </SplashDescriptionItems>
    )
})

const SplashDescription = observer(props => {
    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (StoreSplash.IsEmptyCart()) {
            StoreSplash.SetSubscription(false)
        }
    }, [])

    useEffect(() => {
        const disposeEmptyCart = reaction(
            () => StoreSplash.IsEmptyCart(),
            () => {
                if (StoreSplash.IsEmptyCart()) {
                    StoreSplash.SetSubscription(false)
                } else {
                    StoreSplash.SetSubscription(true)
                }
            }
        )
        return () => {
            disposeEmptyCart()
        }
    }, [])

    return (
        <div className={`splash--description ${StoreSplash.IsFirstPage() ? 'first-page' : 'payment-page'}`}>
            <div className="splash--description-contact">
                {StoreSplash.IsPaymentPage() && (
                    <Card>
                        <CardBody>
                            <SplashCustomerDetail />
                        </CardBody>
                    </Card>
                )}
                <Card>
                    <CardBody>
                        <SplashDescriptionItems
                            type="label-content"
                            underline="dashed"
                            noSpaceTop
                            style={{
                                gridTemplateColumns: 'repeat(2, auto)'
                            }}>
                            <SplashDescriptionItemsLabel title={language.listen('splash_referral_id')} />
                            <SplashDescriptionItemsContent>
                                {StoreSplash.getReferralId() && (
                                    <>
                                        {StoreSplash.getReferralId()}, {StoreSplash.getReferralName()}
                                    </>
                                )}
                                {!StoreSplash.getReferralId() && <Skeleton.Avatar active style={{ width: 200, height: 20 }} />}
                            </SplashDescriptionItemsContent>
                        </SplashDescriptionItems>
                        <SplashDescriptionItems type="content" underline="dashed">
                            <SplashDescriptionItemsContent>
                                <div style={{ display: 'grid', gridTemplateColumns: 'auto 20px', alignItems: 'center', borderRadius: '20px' }}>
                                    <div>
                                        <h4>{language.listen('splash_subscribe_now')}</h4>
                                        <small>{language.listen('splash_change_or_cancel')}</small>
                                    </div>
                                    <div>
                                        <Checkbox
                                            onChange={e => {
                                                if (StoreSplash.IsEmptyCart()) {
                                                    window.scrollTo(0, 0)
                                                } else {
                                                    StoreSplash.SetSubscription(e.target.checked)
                                                }
                                            }}
                                            checked={StoreSplash.IsSubscribeMonthly()}
                                            className={`${StoreSplash.IsEmptyCart() ? 'ant-checkbox-disabled' : ''}`}
                                        >
                                        </Checkbox>
                                    </div>
                                </div>
                            </SplashDescriptionItemsContent>
                        </SplashDescriptionItems>
                        {StoreSplash.IsPaymentPage() && (
                            <>
                                <SubscriptionDescriptionBlock />
                                <SplashDescriptionItems type="label-content" underline="solid" spaceBottom noSpaceTop>
                                    <SplashDescriptionItemsLabel
                                        title={
                                            <If condition={StoreSplash.getReferralId() === null}>
                                                <Then>
                                                    <Skeleton.Avatar active style={{ width: 80, height: 20 }} />
                                                </Then>
                                                <Else>
                                                    {language.listen('splash_shipping')}&nbsp;
                                                    <DisplayDeliveryNote splash />
                                                </Else>
                                            </If>
                                        }
                                        className="label-black"
                                    />
                                    <SplashDescriptionItemsContent>
                                        <If condition={StoreSplash.getReferralId() === null || (StoreSplash.Validate.loading === true && StoreSplash.Validate.paymentLoading === false)}>
                                            <Then>
                                                <Skeleton.Avatar active style={{ width: 80, height: 20 }} />
                                            </Then>
                                            <Else>{StoreSplash.GetShippingPrice()}</Else>
                                        </If>
                                    </SplashDescriptionItemsContent>
                                </SplashDescriptionItems>
                                <SplashDescriptionItems type="label-content">
                                    <SplashDescriptionItemsLabel
                                        title={
                                            <If condition={StoreSplash.getReferralId() === null}>
                                                <Then>
                                                    <Skeleton.Avatar active style={{ width: 90, height: 20 }} />
                                                </Then>
                                                <Else>
                                                    <>{language.listen('splash_total')}</>
                                                </Else>
                                            </If>
                                        }
                                        className="label-black bold"
                                    />
                                    <SplashDescriptionItemsContent className="bold">
                                        <If condition={StoreSplash.getReferralId() === null || (StoreSplash.Validate.loading === true && StoreSplash.Validate.paymentLoading === false)}>
                                            <Then>
                                                <Skeleton.Avatar active style={{ width: 80, height: 20 }} />
                                            </Then>
                                            <Else>
                                                {StoreSplash.GetTotalPrice('orderTerms')} {StoreSplash.Currency()}
                                            </Else>
                                        </If>
                                    </SplashDescriptionItemsContent>
                                </SplashDescriptionItems>
                            </>
                        )}
                        {StoreSplash.IsFirstPage() && <SubscriptionDescriptionBlock />}
                    </CardBody>
                    {StoreSplash.Validate.isWarning === true && (
                        <div style={{ padding: '0px 20px' }}>
                            <div className="alert alert-warning">
                                <div className="text-center">
                                    <ul style={{ listStyle: 'none' }}>
                                        {StoreSplash.GetError().map((v, k) => {
                                            return <li key={k}>{dictionary(v, true, false)}</li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}

                    {StoreSplash.IsFirstPage() && (
                        <CardFooter>
                            <Button
                                type="primary"
                                loading={StoreSplash.Validate.loading}
                                onClick={() => {
                                    if (StoreSplash.getReferralId() === null || StoreSplash.IsEmptyCart()) {
                                        window.scrollTo(0, 0)
                                    } else {
                                        StoreSplash.SaveState('checked-out').then(response => {
                                            StoreSplash.setAdditionalProducts(0)
                                            StoreSplash.ClearError()
                                            StoreSplash.Steps.isCheckedOut = true
                                            let buildPath = StoreSplash.CreatePathUrl('shipping-detail', {
                                                stateRefId: response.refId
                                            })
                                            history.push(buildPath)
                                        })
                                    }
                                }}
                                style={StoreSplash.getReferralId() === null || StoreSplash.IsEmptyCart() ? { opacity: '0.5' } : {}}
                            >
                                {language.listen('splash_checkout')}
                            </Button>
                        </CardFooter>
                    )}
                </Card>
            </div>
            {StoreSplash.IsPaymentPage() && <SplashPayWithCreditCard />}
        </div>
    )
})

const SplashDescriptionItems = observer(({ children, type, underline, noSpaceBottom, noSpaceTop, noSpaceTopBottom, spaceBottom, style = {} }) => {
    return (
        <div
            style={style}
            className={classnames(`splash--description-items${type === 'content' ? '-only-content' : ''}`, {
                'underline-dashed': underline === 'dashed',
                'underline-solid': underline === 'solid',
                '': underline === undefined,
                'no-space-top-bottom': noSpaceTopBottom,
                'no-space-bottom': noSpaceBottom,
                'no-space-top': noSpaceTop,
                'space-bottom': spaceBottom
            })}>
            {children}
        </div>
    )
})

const SplashDescriptionItemsLabel = observer(props => {
    return <div className={`splash--description-items-label ${props.className || ''}`}>{props.children || props.title}</div>
})

const SplashDescriptionItemsContent = observer(props => {
    return <div className={`splash--description-items-content ${props.className || ''}`}>{props.children}</div>
})

const SplashCustomerDetail = observer(props => {
    return (
        <>
            <SplashDescriptionItems noSpaceTopBottom>
                <SplashDescriptionItemsLabel title={language.listen('splash_name')} />
                <SplashDescriptionItemsContent>
                    <If condition={StoreSplash.getReferralId() === null}>
                        <Then>
                            <Skeleton.Avatar active style={{ width: 120, height: 20 }} />
                        </Then>
                        <Else>
                            {get(StoreSplash.CheckOut(), 'data.order.shipToName.firstName', '')}
                            &nbsp;
                            {get(StoreSplash.CheckOut(), 'data.order.shipToName.lastName', '')}
                        </Else>
                    </If>
                </SplashDescriptionItemsContent>
            </SplashDescriptionItems>
            <SplashDescriptionItems noSpaceTopBottom>
                <SplashDescriptionItemsLabel title={language.listen('splash_address')} />
                <SplashDescriptionItemsContent>
                    <If condition={StoreSplash.getReferralId() === null}>
                        <Then>
                            <Skeleton.Avatar active style={{ width: 80, height: 20 }} />
                        </Then>
                        <Else>
                            {Object.keys(get(StoreSplash.CheckOut(), 'data.order.shipToAddress', {})).map(v => {
                                return StoreSplash.CheckOut().data.order.shipToAddress[v] + ' '
                            })}
                        </Else>
                    </If>
                </SplashDescriptionItemsContent>
            </SplashDescriptionItems>
            <SplashDescriptionItems noSpaceTopBottom>
                <SplashDescriptionItemsLabel title={language.listen('splash_mobile')} />
                <SplashDescriptionItemsContent>
                    <If condition={StoreSplash.getReferralId() === null}>
                        <Then>
                            <Skeleton.Avatar active style={{ width: 100, height: 20 }} />
                        </Then>
                        <Else>{get(StoreSplash.CheckOut(), 'data.order.shipToPhone', '')}</Else>
                    </If>
                </SplashDescriptionItemsContent>
            </SplashDescriptionItems>
            <SplashDescriptionItems noSpaceTopBottom>
                <SplashDescriptionItemsLabel title={language.listen('splash_email')} />
                <SplashDescriptionItemsContent>
                    <If condition={StoreSplash.getReferralId() === null}>
                        <Then>
                            <Skeleton.Avatar active style={{ width: 180, height: 20 }} />
                        </Then>
                        <Else>{get(StoreSplash.CheckOut(), 'data.order.shipToEmail', '')}</Else>
                    </If>
                </SplashDescriptionItemsContent>
            </SplashDescriptionItems>
            <SplashDescriptionItems noSpaceTopBottom>
                <SplashDescriptionItemsLabel title={language.listen('splash_send_email_confirm')} />
                <SplashDescriptionItemsContent>
                    <If condition={StoreSplash.getReferralId() === null}>
                        <Then>
                            <Skeleton.Avatar active style={{ width: 180, height: 20 }} />
                        </Then>
                        <Else>{StoreSplash.TermsAgree.sendEmail ? language.listen('splash_yes') : language.listen('splash_no')}</Else>
                    </If>
                </SplashDescriptionItemsContent>
            </SplashDescriptionItems>
        </>
    )
})

export default SplashDescription
