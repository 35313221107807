import { store, checkOutStore } from "../stores/MainStore";
import { CountryConfigs } from "./configs/Configs";
import { makeCustomersInternational } from "../services/Network";
import { CountryCode } from "./configs/Country";
import { isCountryVietnam, isProduction, isCountrySingapore, getEnvironment, getStoreLanguage, getStoreLanguageName } from "./GlobalHelpers";
import { assignCustomer, getCustomerID, getCustomerHumanName, getCustomerEmail, getCustomerCountryCode, getCustomerStatus, getCustomerType, clearCartStorage, getExtendByKey, pushExtend, getCustomerPicture500 } from "./utils/Customer";
import { logn } from "./utils/PikaLog";
import { resetReferralStore } from "./EnrollHelper";
// import LogRocket from 'logrocket';
// import setupLogRocketReact from 'logrocket-react';
import { ACL, AllowList, Menu, appConfig } from './config';
import Raven from '../services/Raven';
import get from "lodash/get";

// const recognizeCustomer = (data, href, token) => {
//     localStorage.removeItem('referral')
//     assignCustomer(data, href, token)
//     try {
//         if (isProduction()) {
//             if (isCountrySingapore()) {
//                 LogRocket.init('zfadbv/ushop', {
//                     network: {
//                         requestSanitizer: request => {
//                             // if the url contains 'ignore'
//                             if (request.url.toLowerCase().indexOf('payment') !== -1) {
//                                 // scrub out the body
//                                 request.body = null
//                             }
//                             // otherwise log the request normally
//                             return request
//                         },
//                     },
//                 })
//                 setupLogRocketReact(LogRocket)

//                 LogRocket.identify(getCustomerID(), {
//                     name: `${getCustomerHumanName().firstName} ${getCustomerHumanName().lastName}`,
//                     email: `${getCustomerEmail()}`,
//                     // Add your own custom user variables here, ie:
//                     Environment: `${getEnvironment()}`,
//                     From: `${getCustomerCountryCode()}`,
//                     Status: `${getCustomerStatus()} (${getCustomerType()})`,
//                     Language: `${getStoreLanguage()} (${getStoreLanguageName()})`,
//                 })

//                 logn('LogRocket has been active.')
//             } else {
//                 logn('LogRocket has been inactive by environment.')
//             }
//         } else {
//             logn('LogRocket has been inactive by environment.')
//         }
//     } catch (e) {
//         console.error(e)
//     }
// }

const getAcl = (type, status) => {
    let acl = null
    let menu = []
    Object.keys(ACL).map((v, k) => {
        
        if (ACL[v].api.type === type && ACL[v].api.status === status) {
            console.log(ACL[v])
            if (ACL[v].allowed === false) {
                return false
            }
            
            acl = ACL[v]
            acl.status = v
            acl.allowList = AllowList[ACL[v].allowed]

            if (acl.allowList === true) {
                acl.mainMenu = Menu()
            } else {
                let menu = []
                let tempMenu = Menu()

                tempMenu.map((b, i) => {
                    if (acl.allowList.sideBar.indexOf(b.key) >= 0) {
                        menu.push(b)
                    }
                })
                acl.mainMenu = menu
            }
            acl.inPageMenu = {}
            Object.keys(AllowList.inPageMenu).map((m, n) => {
                if (acl.inPageMenu[m] === undefined) {
                    acl.inPageMenu[m] = []
                }
                AllowList.inPageMenu[m].map((a, b) => {
                    if (a.allowed.indexOf(ACL[v].allowed) >= 0) {
                        acl.inPageMenu[m].push(JSON.parse(JSON.stringify(a)))
                    }
                })
            })
        }
    })

    if (acl !== null && acl !== false) {
        acl.country = appConfig.country
    }

    return acl
}


const processCustomerData = (token, href, customerToken, customerHref, callback, history) => {

    Raven.getCustomer(token, href)
    .then(response => {
        // also save new picture to acquaintances feature
        const customerID = get(response, 'id.unicity')
        const acquaintances = getExtendByKey('acquaintances')
        const currentRestoreData = get(acquaintances, customerID, false)
        if (currentRestoreData) {
            currentRestoreData.avatar = get(get(response, 'profilePicture.sizes' , []).find((item) => item.size === '500x500'), 'media', '')
            pushExtend({
                acquaintances: {
                    ...acquaintances,
                    [customerID]: currentRestoreData
                }
            })
        }
        
        if (response.mainAddress.country === CountryCode.myanmar) {
            logout()
            callback({ pass: false })
            return false
        }

        if (isCountryVietnam() && response.mainAddress.country !== CountryCode.vietnam) {
            logout(false)
            callback({
                pass: false,
                error: { error_message: 'only vietnam' }
            })
            return false
        }

        if (customerToken !== undefined && customerHref !== undefined) {
            store.facebookConnected = true
        }

        let type = response.type
        if (type === 'Employee') {
            if (response.employmentDetails !== undefined && response.employmentDetails.employerName !== 'Unicity') {
                type = response.employmentDetails.employerName
            }
        }
        
        response.acl = getAcl(type, response.status)
        localStorage.setItem('addprofile', 'pass')
        if (response.acl === false || response.acl === null) {
            callback({
                pass: false
            })
        } else {
            resetReferralStore()
            localStorage.removeItem('logoutDailogShown')

            // recognizeCustomer(response, href, token)

            makeCustomersInternational((error, response) => {
                if (error) console.error('makeCustomersInternational', error)

                checkOutStore.dToken = null
                if (window.location.pathname === '/singapore/d/pfp' ||
                    window.location.pathname === '/singapore/d/ffp') {

                    window.location.href = '/singapore'
                    return false
                }

                if (window.location.pathname === `/${CountryConfigs.Country()}/enroll/payment-response/success`) {
                    history.push(`/${CountryConfigs.Country()}`)
                }

                if (window.location.pathname === `/${CountryConfigs.Country()}/checkout/payment-response/success`) {
                    history.push(`/${CountryConfigs.Country()}`)
                }

                callback({ pass: true })
            })
        }
    })
    .catch(response => {
        callback({ pass: false })
    })
}

export const login = (username, password, callback, customerToken, customerHref, history) => {
    if (customerToken === undefined && customerHref === undefined) {
        Raven.login(username, password)
        .then(response => {
            const href = response.customer.href
            const token = response.token
            processCustomerData(token, href, customerToken, customerHref, callback, history)
        })
        .catch(response => {
            console.log('error', response)
            if (callback !== undefined) {
                let errorResult = null
                if (response === undefined) {
                    errorResult = 'Service Unavailable'
                } else {
                    errorResult = response.error
                }
                console.log('send callback false', errorResult)
                callback({
                    pass: false,
                    error: errorResult
                })
            }
        })
    } else {
        processCustomerData(customerToken, customerHref, undefined, undefined, callback, history)
    }
}

export const logout = (redirect = true) => {

    clearCartStorage()

    localStorage.removeItem('customerData')
    localStorage.removeItem('customerHref')
    localStorage.removeItem('customerToken')
    localStorage.removeItem('user-token')
    localStorage.removeItem('user-href')
    localStorage.removeItem('breadcrumbs')
    localStorage.removeItem('breadcrumbsHistory')
    
    if (redirect === true) {
        localStorage.removeItem('enroll_data')
        localStorage.removeItem('enroll_state')
        localStorage.removeItem('itemData')
        localStorage.removeItem('language')
        localStorage.removeItem('productData')
        localStorage.removeItem('products')
        localStorage.removeItem('footer-data')
        localStorage.removeItem('checkOutStore')
        localStorage.removeItem('storageEnrollCart')
        localStorage.removeItem('EnrollStorage')
        localStorage.removeItem('ShoppingStorage')
        localStorage.removeItem('notificationShown')

        logoutWebview()
        store.authPass = false

        window.location.href = `/${CountryConfigs.Country()}/login`
    } else {
        store.customerData = {}
        store.authPass = false
    }
}

export const logoutWebview = () => {
    // webview logout function only for react native webview browsers
    // if token expires or failed to fetch customer data using token & href => then signout
    if (store.isWebview) {
        localStorage.removeItem('loggedIn');
        localStorage.removeItem('isWebview');
        if (store.latestWebview) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ data: {}, targetFunc: "logout" }));
        } else {
            window.WebViewBridge.send(JSON.stringify({ data: {}, targetFunc: "logout" }));
        }
    }
}