import { isMobile } from '@Bowser'
import Card, { CardFooter } from '@Components/Layouts/Card/Card'
import CardBody from '@Components/Layouts/Card/CardBody'
import CardHeader from '@Components/Layouts/Card/CardHeader'
import Mobi from '@Components/mobiscroll/Mobi'
import StoreSplash from '@Stores/StoreSplash'
import Button from 'antd/es/button'
import Checkbox from 'antd/es/checkbox'
import Form from 'antd/es/form/Form'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import './assets/splash-contact.scss'
import { ReactComponent as EyeClosed } from './assets/img/eye-closed.svg'
import { ReactComponent as EyeOpen } from './assets/img/eye-open.svg'
import Modal from 'antd/es/modal/Modal'
import ReactMarkdown from 'react-markdown/with-html'
import { staticFooter } from '@Stores/MainStore'
import { get } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { language } from '@Language'
import SuperAlertHR from '@Components/fragment/SuperAlertHR'
import { getQueryString } from '@Configs/ConfigsHeader'

const SplashContact = observer(props => {
    const [showTc, setShowTc] = useState(false)
    const history = useHistory()
    const params = useParams()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="splash--contact">
            <h3>{language.listen('splash_shipping_address')}</h3>
            <Card className="splash--contact-info">
                <CardBody>
                    <Form layout="vertical" autoComplete="new-password">
                        {StoreSplash.ShippingDetail.information.map((item, key) => (
                            <Form.Item label={language.listen(item.title)} key={item.key} autoComplete="new-password">
                                <Mobi.Input
                                    type={item.props.type === 'email' ? 'email' : item.props.type === 'text' ? 'text' : isMobile() ? 'tel' : 'text'}
                                    className="form-control text-center"
                                    value={StoreSplash.ShippingDetail.information[key].value}
                                    autoComplete="new-password"
                                    onChange={e => (StoreSplash.ShippingDetail.information[key].value = e.target.value)}
                                    disabled={item.props.disabled ? item.props.disabled : false}
                                />
                            </Form.Item>
                        ))}
                    </Form>
                    <Form layout="vertical" autoComplete="new-password">
                        <input autoComplete="new-password" name="hidden" type="text" className="hide" />
                        {StoreSplash.ShippingDetail.contact.map((item, key) => (
                            <Form.Item label={language.listen(item.title)} key={item.key} autoComplete="new-password">
                                <Mobi.Input
                                    type={item.props.type === 'email' ? 'email' : item.props.type === 'text' ? 'text' : isMobile() ? 'tel' : 'text'}
                                    className="form-control text-center"
                                    value={StoreSplash.ShippingDetail.contact[key].value}
                                    autoComplete="new-password"
                                    onChange={e => (StoreSplash.ShippingDetail.contact[key].value = e.target.value)}
                                    disabled={item.props.disabled ? item.props.disabled : false}
                                />
                            </Form.Item>
                        ))}
                        {StoreSplash.ShippingDetail.password.map((item, key) => (
                            <Form.Item label={language.listen(item.title)} key={item.key}>
                                <Mobi.Input
                                    className="form-control text-center"
                                    value={StoreSplash.GetPassword(key)}
                                    onChange={e => {
                                        StoreSplash.SetPassword(key, e.target.value)
                                    }}
                                    id={`password-input-${key}`}
                                    autoComplete="off"
                                    icon={StoreSplash.showPasswordMasking[key] ? <EyeOpen /> : <EyeClosed />}
                                    onIconClicked={e => {
                                        StoreSplash.SetShowMasking(key)
                                        if (document.getElementById(`password-input-${key}`)) {
                                            document.getElementById(`password-input-${key}`).focus()
                                        }
                                    }}
                                />
                            </Form.Item>
                        ))}

                        {StoreSplash.getReferralId() && (
                            <Form.Item label={language.listen('splash_referral_id')} key="input-referal">
                                <Mobi.Input
                                    className="form-control text-center"
                                    value={`${StoreSplash.getReferralId()} - ${StoreSplash.getReferralNames()}`}
                                    disabled
                                />
                            </Form.Item>
                        )}
                    </Form>
                </CardBody>
            </Card>
            <Card className="splash--terms">
                <CardHeader>
                    <h3>{language.listen('splash_terms_and_condition')}</h3>
                </CardHeader>
                <CardBody>
                    <div className="splash--terms-checkbox">
                        <div>
                            <div>
                                <Checkbox onChange={e => (StoreSplash.TermsAgree.sendEmail = e.target.checked)} checked={StoreSplash.TermsAgree.sendEmail} />
                            </div>
                            <div onClick={() => (StoreSplash.TermsAgree.sendEmail ? (StoreSplash.TermsAgree.sendEmail = false) : (StoreSplash.TermsAgree.sendEmail = true))}>{language.listen('splash_terms_send_email')}</div>
                        </div>
                        <div>
                            <div>
                                <Checkbox onChange={e => (StoreSplash.TermsAgree.agree = e.target.checked)} checked={StoreSplash.TermsAgree.agree} />
                            </div>
                            <div onClick={() => (StoreSplash.TermsAgree.agree ? (StoreSplash.TermsAgree.agree = false) : (StoreSplash.TermsAgree.agree = true))}>
                                {language.listen('splash_terms_agree')}&nbsp;
                                <span className="text-primary cursor-pointer" onClick={() => setShowTc(true)}>
                                    {language.listen('splash_terms_and_condition')}
                                </span>
                            </div>
                        </div>
                    </div>
                    <Modal bodyStyle={{ height: '70vh', overflowY: 'scroll' }} centered className="order-details-modal-tc" visible={showTc} onCancel={() => setShowTc(false)} footer={null}>
                        <ReactMarkdown source={get(staticFooter, 'footerTCOrder[0].html_content.english', '')} escapeHtml={false} />
                    </Modal>
                    {StoreSplash.Validate.isError && (
                        <div className="alert alert-danger">
                            <SuperAlertHR list={StoreSplash.Validate.errorMessages} />
                        </div>
                    )}
                </CardBody>
                <CardFooter>
                    <Button
                        type="primary"
                        loading={StoreSplash.Validate.loading}
                        disabled={StoreSplash.TermsAgree.agree === false && StoreSplash.stateReferrenceId === null}
                        onClick={() => {
                            StoreSplash.ValidateSplash().then(response => {
                                StoreSplash.InitStep('payment')
                                let buildPath = StoreSplash.CreatePathUrl('payment')
                                history.push(buildPath)
                            })
                        }}>
                        {language.listen('splash_continue')}
                    </Button>
                    <Button
                        type="default"
                        onClick={() => {
                            StoreSplash.ClearOrderTerms()
                            StoreSplash.Steps.isCheckedOut = false
                            StoreSplash.InitStep()
                            let buildPath = StoreSplash.CreatePathUrl('')
                            history.push(buildPath)
                        }}>
                        {language.listen('splash_back')}
                    </Button>
                </CardFooter>
            </Card>
        </div>
    )
})

export default SplashContact
