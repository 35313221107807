import { isMobile } from '@Bowser'
import { dictionary } from '@Components/GlobalHelpers'
import Card, { CardFooter } from '@Components/Layouts/Card/Card'
import CardBody from '@Components/Layouts/Card/CardBody'
import CardHeader from '@Components/Layouts/Card/CardHeader'
import Mobi from '@Mobi'
import { isLength } from '@Components/utils/Utils'
import StoreSplash from '@Stores/StoreSplash'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Form from 'antd/es/form'
import { reaction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import './assets/splash-creditcard.scss'
import ReactCreditCard from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
import Button from 'antd/es/button'
import { language } from '@Language'
import { useHistory, useParams } from 'react-router-dom'
import { storeAutoShip } from '@Stores/StoreAutoShip'
import { getQueryString } from '@Configs/ConfigsHeader'

const formItemConfig = {
    colon: false
}

const SplashPayWithCreditCard = observer(props => {
    return (
        <Card className="splash--credit-card">
            <CardHeader>{language.listen('splash_pay_with_credit_card')}</CardHeader>
            <CardBody>
                <div className="splash--credit-card-wrapper">
                    <SplashCreditCard />
                </div>
            </CardBody>
            <CardFooter>
                <ActionFooter />
            </CardFooter>
        </Card>
    )
})

const SplashCreditCard = observer(props => {
    const [isSpinner, setSpinner] = useState(false)
    const [errorMessages, setErrorMessage] = useState([])
    const [cardNumber, setCardNumber] = useState('')
    const [cardFullname, setCardFullname] = useState('')
    const [cardMonth, setCardMonth] = useState('')
    const [cardYear, setCardYear] = useState('')
    const [cardCVV, setCardCVV] = useState('')
    const [cardCVVPattern, setCardCVVPattern] = useState('')
    // dropdown
    const [selectedMonth, setSelectedMonth] = useState('')
    const [selectedYear, setSelectedYear] = useState('')
    const [months] = useState(() => {
        let lm = [{ value: 'none', name: 'MM' }]
        let month = ''
        for (let i = 1; i <= 12; i++) {
            month = i < 10 ? '0' + i : i
            lm.push({ value: month.toString(), name: month })
        }
        return lm
    })
    const [years] = useState(() => {
        const min = new Date().getFullYear()
        const max = 10
        let ly = [{ value: 'none', name: 'YYYY' }]
        for (let i = 0; i <= max; i++) {
            ly.push({ value: (min + i).toString(), name: min + i })
        }
        return ly
    })

    // cleanup mobx credit card
    useEffect(() => {
        // for shopping
        StoreSplash.CreditCard.cardNumber = ''
        StoreSplash.CreditCard.cardYear = ''
        StoreSplash.CreditCard.cardCVV = ''
        StoreSplash.CreditCard.cardMonth = ''
        StoreSplash.CreditCard.cardName = ''
        StoreSplash.CreditCard.validatePass = false

        const disposer = reaction(
            () => language.current,
            language => {
                resetError()
            }
        )

        return () => disposer()
    }, [])

    useEffect(() => {
        if (props.errorMessages !== undefined) {
            if (props.cardErrors !== undefined) {
                const c = props.errorMessages.concat(props.cardErrors)
                setErrorMessage(c)
            } else {
                if (isLength(props.errorMessages) && isLength(errorMessages) === false) {
                    setErrorMessage(props.errorMessages)
                }
            }
        }
    }, [props.errorMessages])

    const back = () => {
        props.backToCheckout()
    }

    const resetError = () => {
        setErrorMessage([])
    }

    const onSetMonth = (event, inst) => {
        // Your custom event handler goes here

        var selected = inst.getVal()
        setSelectedMonth(selected)
        setCardMonth(selected)
        StoreSplash.CreditCard.cardMonth = selected
        if (props.autoShip === true) storeAutoShip.cardMonth = selected
    }
    const onSetYear = (event, inst) => {
        // Your custom event handler goes here

        var selected = inst.getVal()
        setSelectedYear(selected)
        setCardYear(selected)
        StoreSplash.CreditCard.cardYear = selected
        if (props.autoShip === true) storeAutoShip.cardYear = selected
    }

    const passwordPattern = value => {
        let result = ''
        for (var i = 0; i < value.length; i++) {
            result += '*'
        }

        var lastChar = value[value.length - 1]
        let cvvValue = ''

        if (lastChar != '*' && lastChar != undefined) {
            cvvValue = StoreSplash.CreditCard.cardCVV + lastChar
        } else {
            cvvValue = StoreSplash.CreditCard.cardCVV.slice(0, -1)
        }

        StoreSplash.CreditCard.cardCVV = cvvValue

        setCardCVV(result)
    }

    const setCard = (key, value) => {
        resetError()
        //key, value

        switch (key) {
            case 'fullname':
                setCardFullname(value)
                StoreSplash.CreditCard.cardName = value
                if (props.autoShip === true) storeAutoShip.cardName = value
                break
            case 'number':
                if (value.length <= 16) {
                    setCardNumber(value)
                    StoreSplash.CreditCard.cardNumber = value

                    if (props.autoShip === true) storeAutoShip.cardNumber = value
                }

                break
            case 'cvc':
                passwordPattern(value)
                break
            case 'cardMonth':
                setSelectedMonth(value)
                setCardMonth(value)
                StoreSplash.CreditCard.cardMonth = value
                if (props.autoShip === true) storeAutoShip.cardMonth = value
                break
            case 'cardYear':
                setSelectedYear(value)
                setCardYear(value)
                StoreSplash.CreditCard.cardYear = value
                if (props.autoShip === true) storeAutoShip.cardYear = value
                break
            default:
                throw 'CreditCard error. Key = ' + key
        }
    }
    const monthOptionsFactory = () => {
        let lm = [{ value: 'none', text: 'MM', disabled: true }]
        for (let i = 1; i <= 12; i++) {
            const m = i < 10 ? '0' + i : `${i}`
            lm.push({ value: m, text: m })
        }

        return lm
    }
    const yearOptionsFactory = () => {
        const min = new Date().getFullYear()
        const max = 10
        let ly = [{ value: 'none', text: 'YYYY', disabled: true }]
        for (let i = 0; i <= max; i++) {
            const year = `${min + i}`
            ly.push({ value: year, text: year })
        }
        return ly
    }

    const validation = () => {
        setSpinner(true)
        StoreSplash.Validate.loading = true
        StoreSplash.CreditCard.cardNumber = cardNumber
        StoreSplash.CreditCard.cardYear = cardYear
        StoreSplash.CreditCard.cardCVV = cardCVV
        StoreSplash.CreditCard.cardMonth = cardMonth
        StoreSplash.CreditCard.cardName = cardFullname
        StoreSplash.CreditCard.validatePass = false
        props.validate(true)
    }

    const customBtn = () => {
        let customBtn = [
            {
                text: dictionary('u_all_renew_cancel'),
                handler: 'cancel'
            },
            {
                text: dictionary('u_all_renew_ok'),
                handler: 'set'
            }
        ]

        return customBtn
    }

    return (
        <>
            <div className="splash--credit-card-img">
                <ReactCreditCard number={StoreSplash.CreditCard.cardNumber} name={StoreSplash.CreditCard.cardName} expiry={StoreSplash.CreditCard.cardMonth + '/' + StoreSplash.CreditCard.cardYear} cvc={cardCVV} preview={true} $rccs-size="200" />
            </div>
            <Row type="flex" justify="center" className="creditcard">
                <Col span={24}>
                    <Form layout="vertical" className="form form-horizontal margin-top-1x" method="post" id="frmPaymentShopping">
                        <Form.Item {...formItemConfig} label={dictionary('card_number')}>
                            <Mobi.Input type={isMobile() ? 'tel' : 'number'} className="form-control text-center" value={cardNumber} autoComplete="off" onChange={e => setCard('number', e.target.value, e)} />
                        </Form.Item>
                        <Form.Item {...formItemConfig} label={dictionary('card_name')}>
                            <Mobi.Input
                                autoComplete="off"
                                className="card_name"
                                value={cardFullname}
                                onChange={e => {
                                    setCard('fullname', e.target.value)
                                }}
                            />
                        </Form.Item>
                        <Row type="flex" justify="space-between" className="super-pills" gutter="10">
                            <Col span={8}>
                                <Form.Item {...formItemConfig} label={'Expiry Date'} className="m-0">
                                    <Mobi.Select filter={false} display="center" touchUi={true} onSet={onSetMonth} buttons={customBtn()} value={selectedMonth} className="form-control text-center" data={monthOptionsFactory()} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item {...formItemConfig} label={' '} className="m-0">
                                    <Mobi.Select filter={false} display="center" touchUi={true} buttons={customBtn()} onSet={onSetYear} value={selectedYear} className="form-control text-center" data={yearOptionsFactory()} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item {...formItemConfig} label={dictionary('card_cvv')} className="m-0 cvv">
                                    <Mobi.Input
                                        autoComplete="off"
                                        type="tel"
                                        value={cardCVV}
                                        onChange={e => {
                                            setCard('cvc', e.target.value)
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            {StoreSplash.Validate.isError === true && (
                <div style={{ padding: '0px 20px' }}>
                    <div className="alert alert-danger">
                        <div className="text-center">
                            <ul style={{ listStyle: 'none' }}>
                                {StoreSplash.GetError().map((v, k) => {
                                    return <li key={k}>{dictionary(v, true, false)}</li>
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
            {StoreSplash.Validate.isWarning === true && (
                <div style={{ padding: '0px 20px' }}>
                    <div className="alert alert-warning">
                        <div className="text-center">
                            <ul style={{ listStyle: 'none' }}>
                                {StoreSplash.GetError().map((v, k) => {
                                    return <li key={k}>{dictionary(v, true, false)}</li>
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
})

const ActionFooter = observer(props => {
    const history = useHistory()
    const params = useParams()

    return (
        <div>
            <Button
                type="primary"
                loading={StoreSplash.CheckOut().loading || StoreSplash.Validate.loading || StoreSplash.Validate.paymentLoading}
                onClick={() => {
                    StoreSplash.SubmitPayment()
                        .then(response => {
                            history.push(`${StoreSplash.UrlRootPath()}/${StoreSplash.PaymentType()}/payment-response/success?order_id=${response.order_id}&new_id=${response.new_id}`)
                        })
                        .catch(error => {
                            StoreSplash.Validate.paymentLoading = false
                        })
                }}
                disabled={StoreSplash.Validate.paymentLoading}>
                {language.listen('splash_confirm')}
            </Button>
            <Button
                type="default"
                disabled={StoreSplash.CheckOut().loading || StoreSplash.Validate.loading || StoreSplash.Validate.paymentLoading}
                onClick={() => {
                    const refID = getQueryString().ref_id
                    StoreSplash.ClearError()
                    StoreSplash.Steps.isCheckedTermAndTc = false
                    StoreSplash.InitStep('shipping-detail')
                    let buildPath = StoreSplash.CreatePathUrl('shipping-detail')
                    history.push(buildPath)
                }}>
                {language.listen('splash_back')}
            </Button>
        </div>
    )
})

export default SplashPayWithCreditCard
