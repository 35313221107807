import mergeImages from 'merge-images'
import axios from 'axios'

const WATERMARK_URLS = {
    DEFAULT: '/assets/watermarks/watermark.png',
    IDN: '/assets/watermarks/watermark_idn.png',
    THA: '/assets/watermarks/watermark_tha_fit.png'
}

const WATERMARK_TEST = {
    WD01: '/assets/watermarks/watermark-01.png',
    WD02: '/assets/watermarks/watermark-02.png',
    WD03: '/assets/watermarks/watermark-03.png',
    WD04: '/assets/watermarks/watermark-04.png'
}

const TESTING = false
const GetWatermarkTest = name => {
    if (name === 'test1') {
        return WATERMARK_TEST.WD01
    } else if (name === 'test2') {
        return WATERMARK_TEST.WD02
    } else if (name === 'test3') {
        return WATERMARK_TEST.WD03
    } else if (name === 'test4') {
        return WATERMARK_TEST.WD04
    }
}

const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
}

const toImage = async ({ src }) =>
    new Promise((resolve, reject) => {
        try {
            const img = document.createElement('img')
            img.onload = () => resolve(img)
            img.setAttribute('src', src)
            img.setAttribute('crossorigin', 'anonymous')
        } catch (err) {
            console.error(err)
            reject(err)
        }
    })

const getWatermarkImage = async ({ countryCode, name, watermarkUrl }) => {
    // const useWatermarkUrl = TESTING === false ? WATERMARK_URLS[countryCode] || WATERMARK_URLS.DEFAULT : watermarkUrl
    const useWatermarkUrl = watermarkUrl ? watermarkUrl : WATERMARK_URLS[countryCode] || WATERMARK_URLS.DEFAULT
    const watermarkImg = await toImage({ src: useWatermarkUrl })
    return watermarkImg
}

const resizeImage = ({ sourceImage, width, height }) => {
    try {
        const canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height
        canvas.getContext('2d').drawImage(sourceImage, 0, 0, width, height)
        return canvas.toDataURL()
    } catch (err) {
        console.error(err)
        throw err
    }
}
// type position = 'bottomRight' | 'topLeft' | 'center'
const cropWatermarkToPosition = ({ sourceImage, width, height, watermarkWidth = 0, watermarkHeight = 0, position }) => {
    try {
        const canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height
        let positionX = 0
        let positionY = 0
        switch (position) {
            case 'bottomRight':
                positionX = (canvas.width - watermarkWidth) * -1
                positionY = (canvas.height - watermarkHeight) * -1
                break
            case 'center':
                positionX = (canvas.width - watermarkWidth) * -0.5
                positionY = (canvas.height - watermarkHeight) * -0.5
                break

            default:
                break
        }

        canvas.getContext('2d').drawImage(sourceImage, positionX, positionY, width, height, 0, 0, width, height)
        return canvas.toDataURL()
    } catch (err) {
        console.error(err)
        throw err
    }
}

const mergeWatermark = async ({ countryCode, dataUrl, name, watermarkUrl, watermarkResizeTo, position }) => {
    const [originImage, watermarkImage] = await Promise.all([toImage({ src: dataUrl }), getWatermarkImage({ countryCode, name, watermarkUrl })])

    let watermarkSize = originImage.width
    let watermarkHeight = originImage.height
    const isLandscape = originImage.width > originImage.height
    // if (countryCode === 'THA') {
    //     const isLandscape = originImage.width > originImage.height
    //     const resizeTo = watermarkResizeTo / 100
    //     watermarkSize = originImage.width * resizeTo
    //     if (isLandscape && watermarkSize > originImage.height) watermarkSize = originImage.height
    //     else if (!isLandscape && watermarkSize > originImage.width) watermarkSize = originImage.width
    //     watermarkHeight = watermarkSize * 0.75
    // }
    const resizeTo = watermarkResizeTo / 100
    watermarkSize = originImage.width * resizeTo
    watermarkHeight = watermarkSize
    
    isLandscape ? (watermarkHeight = watermarkSize) : (watermarkSize = watermarkHeight)

    const watermarkResized = resizeImage({
        sourceImage: watermarkImage,
        width: watermarkSize,
        height: watermarkHeight
    })

    const watermarkResizedImage = await toImage({ src: watermarkResized })
    const watermarkCroped = cropWatermarkToPosition({
        sourceImage: watermarkResizedImage,
        width: originImage.width,
        height: originImage.height,
        position: position,
        watermarkWidth: watermarkSize,
        watermarkHeight: watermarkHeight
    })

    const merged = await mergeImages([dataUrl, watermarkCroped])

    return merged
}

const getFileExtension = base64 => {
    try {
        const mapExts = {
            'image/jpeg': 'jpg',
            'image/png': 'png'
        }
        const mime = base64.split(';')[0].split(':')[1]
        const ext = mapExts[mime]

        if (!ext) throw new Error('getFileExtension: get not get extionsion file.')

        return ext
    } catch (err) {
        console.error(err)
        throw err
    }
}

const getUploadUrl = async ({ fileType, countryCode, GID, fileExtension }) => {
    try {
        return axios({
            method: 'POST',
            url: `https://member-calls2.unicity.com/s3/uploadUrl/kyc`,
            data: {
                fileType,
                countryCode,
                GID,
                fileExtension
            }
        })
    } catch (err) {
        console.error(err)
        throw err
    }
}

const uploadKyc = async ({ countryCode, GID, dataUrl, fileType, isWatermark, name, watermarkUrl, watermarkResizeTo, position }) => {
    try {
        const fileExtension = isWatermark ? 'jpg' : getFileExtension(dataUrl)

        const [getUrlresponse, mergedWatermark] = await Promise.all([
            getUploadUrl({
                fileType,
                countryCode,
                GID,
                fileExtension
            }),
            isWatermark ? mergeWatermark({ countryCode, dataUrl, name, watermarkUrl, watermarkResizeTo, position }) : null
        ])

        const uploadFile = dataURLtoFile(isWatermark ? mergedWatermark : dataUrl, `${fileType}.${fileExtension}`)

        const { uploadUrl, imageUrl, key } = getUrlresponse.data
        await axios({
            method: 'PUT',
            url: uploadUrl,
            data: uploadFile,
            headers: { 'Content-Type': `image/${fileExtension}` }
        })

        return { imageUrl, key }
    } catch (err) {
        console.error(err)
        throw err
    }
}

export default new (class kycUploader {
    uploadGID = async ({ countryCode, GID, dataUrl, isWatermark = true, name, watermarkUrl, watermarkResizeTo = 1, position }) => {
        try {
            const res = await uploadKyc({
                countryCode,
                GID,
                dataUrl,
                fileType: 'GID',
                isWatermark: isWatermark,
                name,
                watermarkUrl,
                watermarkResizeTo,
                position
            })
            return res
        } catch (err) {
            console.error(err)
            throw err
        }
    }

    uploadSelfie = async ({ countryCode, GID, dataUrl, isWatermark = true, name, watermarkUrl, watermarkResizeTo = 1, position }) => {
        try {
            const res = await uploadKyc({
                countryCode,
                GID,
                dataUrl,
                fileType: 'selfie',
                isWatermark: isWatermark,
                name,
                watermarkUrl,
                watermarkResizeTo,
                position
            })
            return res
        } catch (err) {
            console.error(err)
            throw err
        }
    }

    uploadBankbook = async ({ countryCode, GID, dataUrl, isWatermark = true, name, watermarkUrl, watermarkResizeTo = 1, position }) => {
        try {
            const res = await uploadKyc({
                countryCode,
                GID,
                dataUrl,
                fileType: 'bankbook',
                isWatermark: isWatermark,
                name,
                watermarkUrl,
                watermarkResizeTo,
                position
            })
            return res
        } catch (err) {
            console.error(err)
            throw err
        }
    } 

    uploadPassport = async ({ countryCode, GID, dataUrl, isWatermark = true, name, watermarkUrl, watermarkResizeTo = 1, position }) => {
        try {
            const res = await uploadKyc({
                countryCode,
                GID,
                dataUrl,
                fileType: 'passport',
                isWatermark: isWatermark,
                name,
                watermarkUrl,
                watermarkResizeTo,
                position
            })
            return res
        } catch (err) {
            console.error(err)
            throw err
        }
    }

    uploadWorkPermit = async ({ countryCode, GID, dataUrl, isWatermark = true, name, watermarkUrl, watermarkResizeTo = 1, position }) => {
        try {
            const res = await uploadKyc({
                countryCode,
                GID,
                dataUrl,
                fileType: 'work_permit',
                isWatermark: isWatermark,
                name,
                watermarkUrl,
                watermarkResizeTo,
                position
            })
            return res
        } catch (err) {
            console.error(err)
            throw err
        }
    }
})()
